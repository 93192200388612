import * as React from "react";
import { Form, Col, Button, FormControl, Spinner } from "react-bootstrap";
import { ValidatedInputGroup } from "../../forms/ValidatedInputGroup";
import { LocationRegisterForm } from "./LocationRegisterForm";
import FormErrorMessage from "../../forms/FormErrorMesssage";
import { InputGroup } from "../../forms/InputGroup";
import { MaskValidatedInputGroup } from "../../forms/MaskValidatedInputGroup";
import FormSuccessMessage from "../../forms/FormSuccessMessage";
import { observer } from "mobx-react";
var moment = require("moment-timezone");

export interface LocationRegisterComponentProps {
  form?: LocationRegisterForm;
}

export interface LocationRegisterComponentState {}

@observer
class LocationRegisterComponent extends React.Component<
  LocationRegisterComponentProps,
  LocationRegisterComponentState
> {
  form: LocationRegisterForm;
  constructor(props: LocationRegisterComponentProps) {
    super(props);
    this.form = new LocationRegisterForm();
  }

  listing() {
    let timevalues = moment.tz.names();

    let list2 = [
      "US/Eastern",
      "US/Central",
      "US/Mountain",
      "US/Pacific",
      "US/Alaska",
      "US/Hawaii",
    ];

    let list3 = [];
    for (var i = 0; i < list2.length; i++) {
      for (var j = 0; j < timevalues.length; j++) {
        if (list2[i] === timevalues[j]) {
          list3.push(<option>{`${timevalues[j]}`}</option>);
        }
      }
    }
    return list3;
  }

  handleRegister = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      const res = await this.form.register();

      // if (!res) {
      //   return;
      // }
    }
  };

  handleChange = async () => {
    const emailvalid: any = await this.form.emailvalid(this.props.form.email);
  };

  termsandcondition() {
    return "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected there to (collectively, the “Site”)";
  }

  handleChange1 = async () => {
    const pswdvalid: any = await this.form.pswdverify(
      this.props.form.password,
      this.props.form.confimPass
    );
  };

  handleChange2 = async (event) => {
    if (event.target.value === "Choose Timezone") {
      this.form.timezoneselected = false;
    } else {
      this.form.timezone = event.target.value;
      this.form.timezoneselected = true;
    }
  };

  handleChange3 = async (event) => {
    if (event.target.value === "Choose Data Required") {
      this.form.customerdataselected = false;
    } else {
      this.form.customerdata = event.target.value;
      this.form.customerdataselected = true;
    }
  };

  render() {
    return (
      <Form onKeyDown={(e) => this.handleRegister(e)}>
        <FormErrorMessage form={this.form}></FormErrorMessage>

        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="UserName"
              label="Name"
              field={this.form.userName}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="email"
              label="Email"
              field={this.form.email}
              onBlur={() => this.form.emailvalid(this.form.email.value)}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="password"
              label="Password"
              field={this.form.password}
            />
          </Col>
          <Col>
            <MaskValidatedInputGroup
              fieldName="c-password"
              label="Confirm Password"
              field={this.form.confimPass}
              onBlur={() =>
                this.form.pswdverify(
                  this.form.password.value,
                  this.form.confimPass.value
                )
              }
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="CompanyName"
              label="Company Name"
              field={this.form.company}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <ValidatedInputGroup
              fieldName="LocationUrl"
              label="Location URL"
              field={this.form.locationurl}
            />
          </Col>
          <Col>
            <Form.Label style={{ fontSize: "13px", fontWeight: "normal" }}>
              TimeZone
            </Form.Label>
            <FormControl as="select" onChange={this.handleChange2}>
              <option>Choose Timezone</option>
              {this.listing()}
              {/* <option>PACIFIC STANDARD TIME</option>
              <option>MOUNTAIN STANDARD TIME</option>
              <option>CENTRAL STANDARD TIME</option>
              <option>EASTERN STANDARD TIME</option>
              <option>UTC</option>
              <option>IST</option> */}
            </FormControl>
          </Col>
          <Col>
            <Form.Label style={{ fontSize: "13px", fontWeight: "normal" }}>
              Require Customer Data
            </Form.Label>
            <FormControl as="select" onChange={this.handleChange3}>
              <option>Choose Data Required</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </FormControl>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="Address1"
              label="Address1"
              field={this.form.address1}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="Address1"
              label="Address2"
              field={this.form.address2}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="city"
              label="City"
              field={this.form.city}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="state"
              label="State"
              field={this.form.state}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="phonenumber"
              label="Phone Number"
              field={this.form.phonenumber}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="Zipcode"
              label="Zip/Postal code"
              field={this.form.zipcode}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroup
              disabled
              as="textarea"
              rows="5"
              label="Terms and Conditions for customers"
              placeholder={this.termsandcondition()}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className="text-center">
            <Button onClick={() => this.form.back()} variant="secondary">
              Back
            </Button>{" "}
            <Button
              onClick={() => this.form.register()}
              variant="success"
              disabled={this.form.isWorking}
            >
              {this.form.isWorking ? (
                <span>
                  Registering...
                  <i className="fa fa-refresh fa-spin" />
                </span>
              ) : (
                "Register"
              )}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default LocationRegisterComponent;
