import React from "react";
// import LocationList from "../locationAdmin/location-list/LocationListPage";
import SuperAdminLocationLogsList from "./superadmin-location-list/SuperAdminLocationlogslist";
import { SuperAdminLocationLogsStore } from "./superadmin-location-list/SuperAdminLocationlogsstore";
import { Navbar, Button, Nav, Badge } from "react-bootstrap";
import logo from "../Gravitycareslogo.jpg";
import AuthService from "../Auth/AuthService";
import { RouteChildrenProps } from "react-router-dom";
import { observer } from "mobx-react";

export interface SuperAdminLocationLogsPageProps
  extends RouteChildrenProps<{ locationID: string }> {
  user2?: any;
}

export interface SuperAdminLocationLogsPageState {}

@observer
class SuperAdminLocationLogsPage extends React.Component<
  SuperAdminLocationLogsPageProps,
  SuperAdminLocationLogsPageState
> {
  store: SuperAdminLocationLogsStore;
  constructor(props: SuperAdminLocationLogsPageProps) {
    super(props);
    this.state = {};

    this.store = new SuperAdminLocationLogsStore();
  }

  componentDidMount() {
    const { locationID } = this.props.match.params;
    this.store.load(locationID);
  }

  render() {
    // const locationname = JSON.parse(localStorage.getItem("user")).LocationName;
    return (
      <div className="hieght width-fit">
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="150"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <Badge
                variant="dark"
                style={{ fontSize: 22, fontWeight: "normal" }}
              >
                Welcome {this.store.locationame}
              </Badge>{" "}
              <Button
                variant="secondary"
                onClick={() => {
                  window.location.replace("/superadmindashboard");
                }}
              >
                Back
              </Button>
              {"  "}
              <Button
                onClick={() => {
                  window.location.assign(
                    "/superadmin/editlocation/" +
                      `${this.props.match.params.locationID}`
                  );
                }}
              >
                <i className="fas fa-pencil-alt" /> {""}
                Edit Location
              </Button>
              {"  "}
              <Button
                onClick={() => {
                  window.open("/superadmin/QRdownloadpage");
                  // window.print(); Prints entire page
                }}
              >
                <i className="fas fa-download" /> Download QR
              </Button>{" "}
              <Button
                onClick={() => {
                  AuthService.logout();
                  window.location.replace("/login");
                }}
              >
                Logout
              </Button>
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
        <div className=" p-5">
          <SuperAdminLocationLogsList
            store={this.store}
          ></SuperAdminLocationLogsList>
        </div>

        <Navbar className="justify-content-center" fixed="bottom">
          <span>Powered by Gravity Payments and Corezi.com</span>
        </Navbar>
      </div>
    );
  }
}

export default SuperAdminLocationLogsPage;
