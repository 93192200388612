import React from "react";
import LocationRegisterComponent from "./locationregisterform/LocationRegisterFormComponent";
import { Navbar } from "react-bootstrap";
import logo from "../Gravitycareslogo.jpg";

export interface LocationRegisterPageProps {}

export interface LocationRegisterPageState {}

class LocationRegisterPage extends React.Component<
  LocationRegisterPageProps,
  LocationRegisterPageState
> {
  constructor(props: LocationRegisterPageProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Navbar className="h-25">
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="150"
              height="70"
              className="d-inline-block p-2 align-top"
            />
          </Navbar.Brand>
        </Navbar>
        <h4 className="text-center mt-4">User Registration Form</h4>
        <div className="card register p-4 container">
          <LocationRegisterComponent />
        </div>
      </div>
    );
  }
}

export default LocationRegisterPage;
