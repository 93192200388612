import axios from "axios";

export const API_URL =
  "https://w61zltxer3.execute-api.us-west-2.amazonaws.com/dev/";
export const QR = "https://www.gravitycares.com/location/";

class AuthService {
  async login(username, password) {
    const data = await axios
      .post(API_URL + "User/login", {
        EmailId: username,
        Password: password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response) {
          return e.response.data;
        }
        return e;
      });
    return data;
  }

  logout() {
    localStorage.removeItem("user");
    // localStorage.removeItem("user1");
  }

  register(username, email, password) {
    return axios.post(API_URL + "User/signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  // OTP Module Stopped as per Gravity cares request:::
  getUser1() {
    return JSON.parse(localStorage.getItem("user1"));
  }

  // OTP Module Stopped as per Gravity cares request:::
  async verifyOTP(otp) {
    const data = await axios
      .post(API_URL + "User/verifyotp", {
        otp: otp,
        UserID: JSON.parse(localStorage.getItem("user")),
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user1", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response) {
          return e.response.data;
        }
        return e;
      });
    return data;
  }
}

export default new AuthService();
