import * as React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { ChangePswdForm } from "../Changepswd/ChangePswdForm";
import FormErrorMessage from "../../forms/FormErrorMesssage";
import { MaskValidatedInputGroup } from "../../forms/MaskValidatedInputGroup";
import { InputGroup } from "../../forms/InputGroup";
import FormSuccessMessage from "../../forms/FormSuccessMessage";
import { observer } from "mobx-react";

export interface ChangePswdComponentProps {
  form?: ChangePswdForm;
}

export interface ChangePswdComponentState {}

@observer
class ChangePswdComponent extends React.Component<
  ChangePswdComponentProps,
  ChangePswdComponentState
> {
  form: ChangePswdForm;
  constructor(props: ChangePswdComponentProps) {
    super(props);
    this.form = new ChangePswdForm();
  }

  handlepswdchange = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      this.form.pswdverify(
        this.form.password.value,
        this.form.confimPass.value
      );

      await this.form.register();
    }
  };

  handleChange = async () => {
    const pswdvalid: any = await this.form.pswdverify(
      this.props.form.password,
      this.props.form.confimPass
    );
  };

  render() {
    return (
      <Form onKeyDown={(e) => this.handlepswdchange(e)}>
        <FormErrorMessage form={this.form}></FormErrorMessage>
        <FormSuccessMessage form={this.form}></FormSuccessMessage>

        <Form.Row>
          <Col>
            <MaskValidatedInputGroup
              fieldName="password"
              label="Old Password"
              field={this.form.oldpassword}
            ></MaskValidatedInputGroup>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <MaskValidatedInputGroup
              fieldName="password1"
              label="New Password"
              field={this.form.password}
            ></MaskValidatedInputGroup>
          </Col>

          <Col>
            <MaskValidatedInputGroup
              fieldName="c-password"
              label="Confirm New Password"
              field={this.form.confimPass}
              onBlur={() =>
                this.form.pswdverify(
                  this.form.password.value,
                  this.form.confimPass.value
                )
              }
            ></MaskValidatedInputGroup>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col className="text-center">
            <Button onClick={() => this.form.back()} variant="secondary">
              Back
            </Button>{" "}
            <Button
              onClick={() => this.form.register()}
              disabled={this.form.isWorking}
              variant="success"
            >
              {this.form.isWorking ? "Registering..." : "Submit"}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default ChangePswdComponent;
