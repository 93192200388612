import React from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Route } from "react-router-dom";
import VisitorRegisterFormPage from "./visitor-register/VisitorRegisterFormPage";
import { SuccessPageComponent } from "./visitor-register/FormSuccessComponent";
import { PrivateRoute } from "./Auth/PrivateRoute";
import LoginPage from "./login/LoginPage";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import LocationAdminPage from "./locationAdmin/LocationAdminPage";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Provider, observer } from "mobx-react";
import AuthService from "./Auth/AuthService";
import LocationRegisterPage from "./locationuserRegister/UserRegisterPage";
import OtpPage from "./login/OtpPage";
import SuperAdminPage from "./superAdmin/superAdminPage";
import { UserRegisterSuccessPageComponent } from "./locationuserRegister/locationregisterform/UserRegisterSuccessComponent";
import { RegisterAdminSuccessPageComponent } from "./superAdmin/superadmin-guest-register/registeradminsuccesscomponent";
import QRdownloadpage from "./locationAdmin/QRdownloadpage";
import RegisterAdminPage from "./superAdmin/registeradminpage";
import SuperAdminLocationLogsPage from "./superAdmin/SuperAdminLocationlogscomponent";
import ChangePswdPage from "./locationAdmin/ChangePswdPage";
import { ChangePswdSuccessPageComponent } from "./locationAdmin/Changepswd/ChangePswdSuccessComponent";
import ForgetPswdPage from "./login/ForgetPswdPage";
import EditLocationPage from "./superAdmin/EditLocationPage";
import { EditLocationSuccessPageComponent } from "./superAdmin/superadmin-location-list/EditLocationSuccessComponent";

@observer
class App extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
  }

  get authed(): boolean {
    return (
      (AuthService.getCurrentUser() &&
        AuthService.getCurrentUser().accessToken &&
        AuthService.getCurrentUser().RoleID === "locationadmin" &&
        true) ||
      false
    );
  }

  get authed1(): boolean {
    return (
      (AuthService.getCurrentUser() &&
        AuthService.getCurrentUser().RoleID === "superadmin" &&
        AuthService.getCurrentUser().accessToken &&
        true) ||
      false
    );
  }

  // get authed(): boolean {
  //   return (
  //     (AuthService.getCurrentUser() &&
  //       AuthService.getUser1() &&
  //       AuthService.getCurrentUser().accessToken &&
  //       AuthService.getUser1().RoleID === "locationadmin" &&
  //       true) ||
  //     false
  //   );
  // }

  // get authed1(): boolean {
  //   return (
  //     (AuthService.getUser1() &&
  //       AuthService.getCurrentUser() &&
  //       AuthService.getUser1().RoleID === "superadmin" &&
  //       AuthService.getCurrentUser().accessToken &&
  //       true) ||
  //     false
  //   );
  // }

  get user(): object {
    return AuthService.getCurrentUser();
  }

  // get user1(): object {
  //   return AuthService.getUser1();
  // }

  render() {
    return (
      <BrowserRouter>
        <Provider user={this.user}>
          {/* <Provider user={this.user} user1={this.user1}> */}
          <Route exact path={"/"} component={LoginPage} />
          <Route exact path={"/login"} component={LoginPage} />
          <Route
            exact
            path="/location/:id"
            component={VisitorRegisterFormPage}
          />
          <Route
            exact
            path="/usersuccesspage"
            component={UserRegisterSuccessPageComponent}
          />
          <Route
            exact
            path="/adminsuccesspage"
            component={RegisterAdminSuccessPageComponent}
          />
          <Route
            exact
            path="/adminpswdsuccesspage"
            component={ChangePswdSuccessPageComponent}
          />

          <Route
            exact
            path="/locationupdate/successpage"
            component={EditLocationSuccessPageComponent}
          />
          <Route exact path="/success" component={SuccessPageComponent} />

          {/* <Route exact path="/OtpVerification" component={OtpPage} /> */}
          <Route exact path="/forgetpassword/otp" component={ForgetPswdPage} />

          <PrivateRoute
            user
            authed={this.authed}
            exact
            path={"/QRdownloadpage"}
            component={QRdownloadpage}
          />

          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/superadmin/QRdownloadpage"}
            component={QRdownloadpage}
          />

          <PrivateRoute
            user
            authed={this.authed}
            exact
            path={"/admindashboard"}
            component={LocationAdminPage}
          />
          <PrivateRoute
            user
            authed={this.authed}
            exact
            path={"/adminpswdchange"}
            component={ChangePswdPage}
          />
          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/superadmindashboard"}
            component={SuperAdminPage}
          />
          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/register"}
            component={LocationRegisterPage}
          />
          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/registeradmin"}
            component={RegisterAdminPage}
          />
          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/superadmin/locationlogs/:locationID"}
            component={SuperAdminLocationLogsPage}
          />
          <PrivateRoute
            user
            authed={this.authed1}
            exact
            path={"/superadmin/editlocation/:locationID"}
            component={EditLocationPage}
          />
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
