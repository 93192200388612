import React from "react";
import { Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import { BaseForm } from "../forms/BaseForm";

export interface TermsModalProps {
  unmount?: (data?: any) => void;
  form?: BaseForm;
}

@observer
class TermsModal extends React.Component<TermsModalProps, {}> {
  constructor(props: TermsModalProps) {
    super(props);
    this.state = {};
  }
  onClose = () => {
    this.props.unmount();
  };

  render() {
    return (
      <Modal show={true} onHide={() => this.onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body> Hello</Modal.Body>
      </Modal>
    );
  }
}

export default TermsModal;
