import { BaseForm } from "../../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import { EmailValidatedField } from "../../forms/validated-fields/EmailValidatedField";
import { PasswordValidatedField } from "../../forms/validated-fields/PasswordValidatedField";
import { NameValidatedField } from "../../forms/validated-fields/NameValidatedField";
import { NumberOnlyValidatedField } from "../../forms/validated-fields/NumberOnlyValidatedField";
import { PostalCodeValidatedField } from "../../forms/validated-fields/PostalCodeValidatedField";
import { PhoneValidatedField } from "../../forms/validated-fields/PhoneValidatedField";
import ApiService from "../../Auth/ApiService";
import { computed, action, observable } from "mobx";

export class EditLocationForm extends BaseForm {
  userName = new ValidatedField({ required: true });
  email = new EmailValidatedField({ required: true });
  password = new PasswordValidatedField({ required: true });
  confimPass = new PasswordValidatedField({ required: true });
  company = new NameValidatedField({ required: true });
  address1 = new ValidatedField({ required: true });
  address2 = new ValidatedField({ required: false });
  state = new ValidatedField({ required: true });
  city = new ValidatedField({ required: true });
  phonenumber = new PhoneValidatedField({ required: true });
  zipcode = new PostalCodeValidatedField({ required: true });
  locationurl = new ValidatedField({ required: true });
  @observable timezone: string;
  timezoneselected: boolean = false;
  confirmPasserror: boolean = false;
  @observable customerdata: string;
  customerdataboolean: boolean;
  customerdataselected: boolean = false;
  @observable LocationID: string;
  @observable userid: string;

  constructor() {
    super();
    this.userName.handleChange("");
  }

  @computed
  get allFields(): ValidatedField[] {
    return [
      this.userName,
      this.company,
      this.city,
      this.address1,
      this.address2,
      this.state,
      this.phonenumber,
      this.zipcode,
      this.locationurl,
    ];
  }

  @computed
  get isValid(): boolean {
    return this.allFields.every((field) => field.isValid);
  }

  @computed
  get timezonevalid(): boolean {
    return this.timezoneselected;
  }

  @computed
  get customerdatavalid(): boolean {
    return this.customerdataselected;
  }

  termsandcondition() {
    return "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected there to (collectively, the “Site”)";
  }

  back() {
    const rolemake: any = JSON.parse(
      localStorage.getItem("user").split(",")[1].split(":")[1]
    );

    const locationid: any = JSON.parse(localStorage.getItem("user")).LocationID;
    if (rolemake === "superadmin") {
      window.location.assign("/superadmin/locationlogs/" + `${locationid}`);
    } else {
      window.location.assign("/admindashboard");
    }
  }

  @action
  async update() {
    if (!this.isValid) {
      this.allFields.forEach((field) => field.handleSubmit());
      return;
    }

    if (!this.timezonevalid) {
      this.setError("Choose timezone");
      return;
    }

    if (!this.customerdatavalid) {
      this.setError("Choose Require Customer Data");
      return;
    }

    if (this.customerdata === "true") {
      this.customerdataboolean = true;
    } else {
      this.customerdataboolean = false;
    }

    const body = {
      user: {
        UserName: this.userName.value,
        PhoneNumber: this.phonenumber.value,
        UserID: this.userid,
      },
      location: {
        LocationID: this.LocationID,
        LocationName: this.company.value,
        Address1: this.address1.value,
        Address2: this.address2.value,
        City: this.city.value,
        State: this.state.value,
        ZipCode: this.zipcode.value,
        T_and_C: this.termsandcondition(),
        // DefaultStayDuration: this.time.value,
        MainURL: this.locationurl.value,
        TimeZone: this.timezone,
        CustomerData: this.customerdataboolean,
      },
    };

    try {
      this.setWorking();

      const updation: any = await ApiService.post("Location/update", body);

      if (!updation || !updation.UserID) {
        this.setError(updation);
      } else {
        window.location.assign("/locationupdate/successpage");
      }
      this.setReady();
    } catch (e) {
      this.setError(e);
    }
  }

  @action
  async initializeFields(value) {
    if (!value) {
      return;
    }

    this.userid = value.user.user_UserID;
    this.userName.init(value.user.user_UserName);
    this.email.init(value.user.user_EmailId);
    this.LocationID = value.Location.LocationID;
    this.company.init(value.Location.LocationName);
    this.city.init(value.Location.City);
    this.address1.init(value.Location.Address1);
    this.address2.init(value.Location.Address2);
    this.state.init(value.Location.State);
    this.phonenumber.init(value.user.user_PhoneNumber);
    this.zipcode.init(value.Location.ZipCode);
    this.locationurl.init(value.Location.MainURL);
    if (value.Location.TimeZone) {
      this.timezone = value.Location.TimeZone;
      this.timezoneselected = true;
    } else {
      this.timezone = value.Location.TimeZone;
      this.timezoneselected = true;
    }
    if (value.Location.CustomerData === true) {
      this.customerdata = "YES";
      this.customerdataselected = true;
    } else {
      this.customerdata = "NO";
      this.customerdataselected = true;
    }
  }
}
