import * as React from "react";
import { VisitorFormComponent } from "./VisitoresRegistorComponent";
import { VisitorForm } from "./VisitorRegisterForm";
import { RouteComponentProps } from "react-router-dom";

export interface VisitorRegisterFormPageProps
  extends RouteComponentProps<{ id: string }> {}

class VisitorRegisterFormPage extends React.Component<
  VisitorRegisterFormPageProps,
  {}
> {
  form: VisitorForm;
  constructor(props: VisitorRegisterFormPageProps) {
    super(props);
    this.form = new VisitorForm();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.form.load(id);
  }

  render() {
    return (
      <div>
        <VisitorFormComponent form={this.form} />
      </div>
    );
  }
}

export default VisitorRegisterFormPage;
