import { BaseForm } from "../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import { PasswordValidatedField } from "../forms/validated-fields/PasswordValidatedField";
import AuthService from "../Auth/AuthService";

export class LoginForm extends BaseForm {
  userName = new ValidatedField({ required: true });
  password = new PasswordValidatedField({ required: true });

  constructor() {
    super();
  }

  login = async () => {
    if (!this.userName.value || !this.password.value) {
      this.userName.handleSubmit();
      this.password.handleSubmit();
      return;
    }

    this.setWorking();
    const login = await AuthService.login(
      this.userName.value,
      this.password.value
    );

    if (!login || !login.UserID) {
      this.setError(login);
      return;
    }
    this.setReady();
    return login;
  };
}
