import React from "react";
import { Alert } from "react-bootstrap";
import { BaseForm } from "./BaseForm";
import { observer } from "mobx-react";

export interface FormErrorMessageProps {
  form: BaseForm;
}

export interface FormErrorMessageState {
  show: boolean;
}
@observer
class FormErrorMessage extends React.Component<
  FormErrorMessageProps,
  FormErrorMessageState
> {
  constructor(props: FormErrorMessageProps) {
    super(props);
    this.state = {
      show: props.form.hasError,
    };
  }
  render() {
    if (this.props.form.hasError) {
      // console.log(this.props.form.hasError);
      // console.log(this.props.form.errorMessage);
      return (
        <Alert variant="danger">
          {/* <Alert.Heading>Error</Alert.Heading> */}
          <p>{this.props.form.errorMessage}</p>
        </Alert>
      );
    }
    return <div></div>;
  }
}

export default FormErrorMessage;
