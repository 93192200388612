import React from "react";
import { Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import { BaseForm } from "../forms/BaseForm";

@observer
class Terms extends React.Component {
  render() {
    return (
      <Modal.Body>
        <Modal.Body>
          <h5>1. Services</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity Payments Inc. (“Gravity”, “we” or “us”) offers customer
            check- in via the website <b>http://gravitycares.com</b> on an “as
            is” software as a service basis together with various third party
            products to facilitate the operation of the service. These terms of
            use as amended from time to time (the “Terms” or ”Agreement”) apply
            to all authorized users (each a “User” or “you”) of Gravity’s
            GravityCares website (“Website”) and associated
            services(collectively, including the Website, “Services”). In these
            Terms, a “User” or “you” includes a third-party visitor who
            checks-in/out using the website or otherwise uses the Services and
            “Client” means a User (or you) who is also a GravityCares account
            holder or subscriber. By creating a GravityCares account or
            otherwise accessing or using the Services, you (and all individual
            users accessing or using the Services on your behalf) accept and
            agree to be bound by these Terms. A User may only use the Services
            in accordance with these Terms. These Terms apply to the provision
            and use of the Services (including any free trial of the Services)
            at all times. If you are entering this Agreement on behalf of an
            organization, you confirm that you have the authority to bind the
            organization and that the organization will be bound by these Terms.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>2. Non-compliance with these Terms</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            If a User, Client, or any other User in relation to the Client’s
            account, does not comply with these Terms (including failing to make
            any payment by a due date), Gravity may, with or without notice,
            cancel or suspend that User and/or Client’s account and refuse to
            provide the Services to that User and/or Client.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>3. Amendments & updates</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity may amend or modify these Terms at any time with or without
            notice to Users. Gravity may also update or modify the Services
            (including adding new features and/ or functionality, which will
            then be subject to these Terms) from time to time. Updates must only
            be obtained from Gravity and each User agrees to receive such
            updates with or without any additional notice. Any User who
            continues to use the Services after any changes have been made to
            these Terms and/ or the Services agrees to those changes. Users
            should therefore check these Terms regularly. New versions of these
            Terms will be dated according to the date on which they took effect.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>4. Accounts & Account Information</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity may, from time to time, open an account for a Client with
            the Client’s permission and such account will be the responsibility
            of the Client. In order to access and use the Services, a Client
            (or, for an organization Client, a representative acting on its
            behalf) will need to be 18 years or older and be a natural person
            (as accounts and logins may not be registered or accessed via bots
            or other automated methods) and provide certain information about
            themselves, including a full legal name, email address, business
            name, URL of their online menu, address, and phone number (“Account
            Information”). All Clients agree to provide true, accurate, current
            and complete Account Information, and to maintain and promptly
            update the Account Information in order to ensure that it remains
            true, accurate, current and complete. A person that creates a Client
            account (including administration account) for a venue or on behalf
            of a Client agrees and represents that they are legally authorized
            to do so. Any unauthorized account creation may result in the
            termination of the account and/ or suspension of the Services in the
            sole discretion of Gravity. Gravity may from time to time contact a
            Client or venue to check or asses the authenticity of an account.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>5. Cancellation of Accounts</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            A Client may only cancel their account by emailing {""}
            <b>gravitycares@gravitypayments.com </b>
            {""} and any Fees paid are non- refundable. If a Client elects to
            cancel their account, cancellation will not become effective unless
            and until no money is owed to Gravity in respect of that Client’s
            account as of the date of cancellation. Gravity may cancel a
            Client’s account for any reason at any time. Content may be
            recovered for up to 30 days after an account is cancelled. Gravity
            is not liable for any loss or damage following, or as a result of,
            cancellation of an account. It is a Client’s responsibility to
            ensure that any content or data which is required is backed-up or
            replicated before cancellation.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>6. Fees</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            All fees for Services (“Fees”) are charged in advance on a monthly
            basis and are strictly non-refundable, including for partial months
            of Services, unused Services or cancellation of the Services for a
            particular site. Gravity is not liable in any way for any lack of
            refunds in connection with the Services. A valid credit card number
            may be required to setup an account.. Gravity’s current monthly fees
            may also be changed from time to time at Gravity’s discretion by
            updating the fees listed on its website or otherwise notifying a
            Client. For an existing Client, this change will become effective at
            the start of the Client’s next billing cycle. Gravity is not liable
            to Clients or any third party for any change in its monthly fees.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>7. Taxes</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            All sums payable by a Client shall be paid in full without set-off
            or counterclaim or any restriction or condition and free and clear
            of any tax or other deductions or withholdings of any nature. If a
            Client or any other person is required by any law or regulation to
            make any deduction or withholding (on account of tax or otherwise)
            from any payment, the Client shall, together with such payment, pay
            such additional amount as will ensure that Gravity receives (free
            and clear of any tax or other deductions or withholdings) the full
            amount which it would have received if no such deduction or
            withholding had been required.
          </p>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            All sums payable by a Client are, unless expressly stated otherwise,
            exclusive of GST, sales, use, goods and services, value added or
            other similar tax or levy, and any penalties and/or interest related
            thereto (together, “GST Tax”). If GST Tax is payable on any supply,
            the Client must upon receiving a tax invoice from Gravity pay to
            Gravity an amount equal to the GST Tax payable on the supply. This
            amount will be paid in addition to, and at the same time as, the
            consideration for the supply.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>8. Responsibility</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Each User will be responsible for all activity that results from or
            is in connection with their use of the Services. A Client is also
            responsible for all other Users’ activities that result from or are
            in connection with the use of the Services in relation to the
            Client’s account. Gravity disclaims any and all liability in
            relation to a person’s use of the Services, and will not be held
            accountable if a person suffers any loss or damage as a result of
            their use of the Services, including (but not limited to) any loss
            or damage resulting from the cancellation of any Client account.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>9. Services are provided “as is”</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            The Services are provided on an “as is” and “as available” basis,
            and the use of them is at your sole risk. Gravity does not warrant
            that the Services will meet your specific requirements, the Services
            will be uninterrupted, timely, secure, or error-free, the results
            that may be obtained from the use of the Services will be accurate
            or reliable, the quality of any products, services, information, or
            other material purchased or obtained through the Services will meet
            your expectations, or any errors in the Services will be corrected.
            You acknowledge that Gravity may use third party suppliers and
            service providers to provide necessary technology required to
            provide the Services. The acts and omissions of those third parties
            may be outside of Gravity’s control, and Gravity shall not be liable
            for any loss or damage suffered as a result of any act or omission
            of any third party. On behalf of itself and such third parties,
            Gravity excludes any other warranties that may be implied or
            otherwise apply under statute or otherwise under applicable law, to
            the maximum extent permitted by law.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>10. Liability</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity shall not be liable for any direct, indirect, incidental,
            special, consequential or exemplary loss or damages, including but
            not limited to, damages for loss of profits, goodwill, use, data or
            other intangible losses (even if Gravity has been advised of the
            possibility of such damages), resulting from: (a) the use, or the
            inability to use, the Services; (b) the cost of procurement of
            substitute goods and services resulting from any goods, data,
            information or services purchased or obtained or messages received
            or transactions entered into through or from the Services; (c)
            unauthorized access to or alteration of your transmissions or data;
            (d) statements or conduct of any third party regarding the Services;
            (e) information provided through the Services or (f) any other
            matter relating to the Services. Gravity’s maximum aggregate
            liability under or in connection with the Services and these Terms
            shall be limited to the amount of Fees paid to Gravity by the Client
            for the immediately preceding 12-month period. The Services are not
            a replacement for emergency systems installed at a site.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>11. Indemnity</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Each User indemnifies, and will keep indemnified, Gravity its
            subsidiaries, affiliated companies and their respective
            shareholders, directors, officers, employees, agents, attorneys,
            successors and/or assigns (the “Indemnified Parties”), against all
            forms of liability, actions, proceedings, demands, costs, charges,
            expenses and any other claims which the Indemnified Parties may
            incur or be subject to or suffer as a result of the relevant User’s
            use of the Services or breach of these Terms. Each Client
            indemnifies, and will keep indemnified, the Indemnified Parties
            against all forms of liability, actions, proceedings, demands,
            costs, charges, expenses and any other claims which the Indemnified
            Parties may incur or be subject to or suffer as a result of a User’s
            use of the Services in relation to the Client’s account.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>12. No Illegal Use</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            No User may use the Services for any illegal or unauthorized
            purpose, or upload any information that is in breach of any
            confidentiality obligation, copyright, trade mark or other
            intellectual property or proprietary rights of any person, entity or
            organization.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>13. No Malicious Use</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Users may only access the Services through the interface provided by
            Gravity, and must not use the Services for any malicious means, or
            to abuse, harass, threaten, intimidate or impersonate any other User
            of the Website or Services. A User must not, without the prior
            written consent of Gravity, cause the Services to be prejudicially
            affected or contested, make any alterations to the Services, assist
            or encourage any third-party application to connect to and read data
            from the Services, or engage in, or assist others to engage in, the
            reverse engineering, disassembly or decompilation of the Services.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>14. Reference to use of Gravity</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            From time to time Gravity may refer to your use of the Services on
            the Website. If you do not wish for your use to be disclosed on the
            Website, please let us know and we will remove the reference.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>15. Intellectual Property</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            All Users agree that Gravity owns all of the intellectual property
            rights existing in the Services. Each User agrees that their use of
            the Services and acceptance of these Terms does not transfer to them
            or any other User any intellectual property rights in the Services;
            however, Gravity claims no intellectual property rights in relation
            to the information or content uploaded by the User in using the
            Services.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>16. Gravity Branding</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            No User may publish or use Gravity’s brand, branding or logos except
            with Gravity’s prior written consent.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>17. System Requirements</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Some elements of the Services will require the User’s devices and
            networks meet certain technical levels (“System Requirements”).
            Gravity takes no responsibility for problems associated with or
            arising from an inability to access, receive or use the Services due
            to device, network or internet speed not meeting the System
            Requirements.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>18. Technical Support & Malfunctions</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity will use reasonable endeavors to promptly address (during
            normal business hours) all technical issues that arise in connection
            with the Services. However, Gravity will not be liable for any loss
            or damage suffered as a result of any partial or total breakdown of,
            or any technical malfunctions or inability to use, the Services
            (either in whole or in part). Each Client agrees that Gravity’s
            support team may access their account from time to time in order to
            diagnose and fix problems.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>19. Security & Privacy</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Clients are responsible for maintaining the security of their
            account/ user names and passwords. Gravity will not be liable for
            any loss or damage that may result from any failure to keep account/
            user names and passwords secure. Gravity makes efforts to keep
            content uploaded by Users secure, but will not be liable for any
            loss or damage that may result from any breach of security, or any
            unauthorized access or use of that content.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            Gravity has published a Privacy Policy for all users at:{" "}
            <b>http:// gravitycares.com/</b> which shall form part of these
            Terms. Clients must comply at all times with all applicable privacy
            and data protection laws which may be in force from time to time in
            relation to the collection, use, holding, and disclosure of personal
            information of Users or other Clients of the Services. Clients may
            not disclose personal information of Users or other Clients to any
            third parties, except in response to law enforcement requests and as
            required by applicable law, court order, or governmental
            regulations.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>20. Waiver, Reading Down & Severance</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Gravity’s failure or delay to exercise a power or right is not a
            waiver of that right or power, and the exercise of a power or right
            does not preclude the future exercise of that or any other power or
            right. No waiver of any power or right by Gravity shall be effective
            unless it is in writing.
          </p>
          <p style={{ textAlign: "justify" }}>
            Where a provision of these Terms would otherwise be unenforceable,
            illegal or void, the effect of that provision shall, so far as is
            possible, be limited and read down such that it is not
            unenforceable, illegal or void, or if not possible to be limited or
            read down, the provision will be severed from the Terms and the
            Terms will continue in full force and effect, except for the severed
            provision.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>21. Interpretation</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            In these Terms: a party includes that party’s successors and
            permitted assigns; a reference to any statute, or any subordinate
            legislation or instrument includes all statutes, subordinate
            legislation or instruments amending, modifying, consolidating,
            re-writing, re-enacting or replacing them and a reference to a
            statute includes all subordinate legislation and instruments made
            under that statute; a reference to “include” and “including” are to
            be construed without limitation; words or expressions importing the
            singular include the plural and vice versa, importing a gender
            include the other gender, and denoting individuals include
            corporations, firms, unincorporated bodies, government authorities
            and instrumentalities; where a word or expression is defined or
            given meaning, another grammatical form has a corresponding meaning;
            if a party comprises one or more persons, these Terms shall apply to
            such persons jointly and severally; a provision of these Terms shall
            not be construed to the disadvantage of a party merely because the
            party was responsible for the preparation of these Terms or the
            inclusion of the provision in these Terms. These Terms are personal
            to each User and no User may assign, transfer, encumber or declare
            trusts in respect of a Gravity account or these Terms without the
            written consent of Gravity.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>22. European Union Users</h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            For Users based in the European Union, European Economic area or the
            United Kingdom, the additional EU Processing Terms available here
            shall apply and form part of these Terms.
          </p>
        </Modal.Body>
        <Modal.Body>
          <h5>23. Governing Law & Jurisdiction </h5>
        </Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            These Terms shall be governed and construed in accordance with laws
            of the State Washington, and all Users submit to the jurisdiction of
            the courts of the State of Washington for any matter or dispute
            arising in relation to these Terms or the Services.
          </p>
        </Modal.Body>
      </Modal.Body>
    );
  }
}

export default Terms;
