import React from "react";
import { SuperAdminLocationListStroe } from "./SuperAdminLocationListStore";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { observer } from "mobx-react";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

export interface SuperAdminLocationListProps {
  store: SuperAdminLocationListStroe;
}

@observer
class SuperAdminLocationList extends React.Component<
  SuperAdminLocationListProps,
  {}
> {
  constructor(props: SuperAdminLocationListProps) {
    super(props);
  }

  render() {
    const { store } = this.props;

    function linkFormatter(cell, row) {
      return (
        <a
          style={{ color: "blue" }}
          href={"/superadmin/locationlogs/" + `${cell}`}
          target="_self"
        >
          {cell}
        </a>
      );
    }

    function urllinkFormatter(cell, row) {
      return (
        <a style={{ color: "blue" }} href={`${cell}`} target="_blank">
          {cell}
        </a>
      );
    }

    const columns = [
      {
        dataField: "LocationName",
        text: "Location Name",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 200 };
        },
      },
      {
        dataField: "Address1",
        text: "Location Address",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 200 };
        },
      },
      // {
      //   dataField: "Address2",
      //   text: "Location Address2",
      //   headerAlign: "center",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: 200 };
      //   },
      // },
      {
        dataField: "City",
        text: "City",
        align: "center",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 100 };
        },
      },
      {
        dataField: "State",
        text: "State",
        align: "center",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 90 };
        },
      },
      {
        dataField: "Country",
        text: "Country",
        align: "center",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 90 };
        },
      },
      {
        dataField: "ZipCode",
        text: "ZipCode",
        align: "center",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 100 };
        },
      },
      {
        dataField: "MainURL",
        text: "Location Menu URL",
        align: "center",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: 450 };
        },
        formatter: urllinkFormatter,
      },
      {
        dataField: "LocationID",
        text: "Location ID",
        headerStyle: () => {
          return { width: 150 };
        },
        formatter: linkFormatter,
      },
    ];

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button
            className="btn btn-primary"
            style={{ float: "right" }}
            onClick={handleClick}
          >
            Export to CSV
          </button>
        </div>
      );
    };

    return (
      <div id="logs">
        <ToolkitProvider
          noDataIndication="No data"
          data={store.records}
          columns={columns}
          loading={store.isLoading}
          search
          exportCSV={{
            fileName: "covidadminlocationlogs.csv",
          }}
        >
          {(props) => (
            <div>
              <MyExportCSV {...props.csvProps} />

              <h5>Search By Location Name</h5>
              <SearchBar {...props.searchProps} />

              <BootstrapTable
                {...props.baseProps}
                striped
                keyField="logstable"
                noDataIndication="No data"
                pagination={paginationFactory()}
                bootstrap4
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(204,255,255,0.2)",
                })}
                hover
                condensed
              ></BootstrapTable>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default SuperAdminLocationList;
