import { BaseForm } from "../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import AuthService from "../Auth/AuthService";
import { OtpDigitValidatedField } from "../forms/validated-fields/OtpDigitValidatedField";
import { EmailValidatedField } from "../forms/validated-fields/EmailValidatedField";
import { PasswordValidatedField } from "../forms/validated-fields/PasswordValidatedField";
import { observable, computed } from "mobx";
import ApiService from "../Auth/ApiService";

export class ForgetPswdPageForm extends BaseForm {
  @observable OTP = new OtpDigitValidatedField({ required: true });
  @observable email = new EmailValidatedField({ required: true });
  @observable nextsection: boolean = false;
  @observable emailbutton: boolean = false;
  @observable nextsection1: boolean = false;
  @observable otpbutton: boolean = false;
  @observable pswdsetbutton: boolean = false;
  @observable usersid: string;
  password = new PasswordValidatedField({ required: true });
  confimPass = new PasswordValidatedField({ required: true });
  confirmPasserror: boolean = false;

  constructor() {
    super();
  }

  @computed
  get allFields(): ValidatedField[] {
    return [this.email, this.password];
  }

  @computed
  get isValid(): boolean {
    return this.allFields.every((field) => field.isValid);
  }

  async pswdverify(pswd1, pswd2) {
    if (pswd1 !== pswd2) {
      this.confimPass.setError("Password Mismatch");
      this.confirmPasserror = false;
    } else {
      this.confirmPasserror = true;
    }
  }

  OtpSent = async () => {
    try {
      const body = {
        EmailId: this.email,
      };

      this.setWorking();
      const sentotp: any = await ApiService.post("User/forgetpswd", body);

      if (!sentotp || !sentotp.UserID) {
        this.setError(sentotp);
        return;
      }
      return sentotp;
    } catch (e) {
      this.setError(e);
    }
  };

  verifyOTP = async () => {
    try {
      const body = {
        UserID: this.usersid,
        otp: this.OTP,
      };

      this.setWorking();
      const otp = <any>await ApiService.post("User/forgetpswdotpcheck", body);

      if (!otp || !otp.UserID) {
        this.setError(otp);
        return;
      }
      this.setReady();
      return otp;
    } catch (e) {
      this.setError(e);
    }
  };

  async screentime() {
    return window.location.assign("/login");
  }

  async newpswdregister() {
    if (!this.confirmPasserror) {
      this.confimPass.setError("Password Mismatch");
      return;
    }

    const body = {
      UserID: this.usersid,
      NewPassword: this.password.value,
    };

    try {
      this.pswdsetbutton = true;
      const pswdregister: any = await ApiService.post("User/newpswdset", body);

      if (!pswdregister || !pswdregister.UserID) {
        this.pswdsetbutton = false;
        this.setError(pswdregister);
      } else {
        this.setSuccess(pswdregister.message);
        window.setInterval(this.screentime, 5000);
      }
    } catch (e) {
      this.setError(e);
    }
  }
}
