import * as React from "react";
import { Form, Col, Button, FormControl, Spinner } from "react-bootstrap";
import { ValidatedInputGroup } from "../../forms/ValidatedInputGroup";
import { EditLocationForm } from "./EditLocationForm";
import { EditLocationStore } from "./EditLocationStore";
import FormErrorMessage from "../../forms/FormErrorMesssage";
import { InputGroup } from "../../forms/InputGroup";
import { MaskValidatedInputGroup } from "../../forms/MaskValidatedInputGroup";
import FormSuccessMessage from "../../forms/FormSuccessMessage";
import { observer } from "mobx-react";
var moment = require("moment-timezone");

export interface EditLocationComponentProps {
  form?: EditLocationForm;
  store?: EditLocationStore;
}

export interface EditLocationComponentState {}

@observer
class EditLocationComponent extends React.Component<
  EditLocationComponentProps,
  EditLocationComponentState
> {
  form: EditLocationForm;
  store: EditLocationStore;
  constructor(props: EditLocationComponentProps) {
    super(props);

    this.form = new EditLocationForm();
    this.store = new EditLocationStore();
  }

  componentDidMount() {
    this.store.load();
  }

  listing() {
    let timevalues = moment.tz.names();

    let list2 = [
      "US/Eastern",
      "US/Central",
      "US/Mountain",
      "US/Pacific",
      "US/Alaska",
      "US/Hawaii",
    ];

    let list3 = [];
    for (var i = 0; i < list2.length; i++) {
      for (var j = 0; j < timevalues.length; j++) {
        if (list2[i] === timevalues[j]) {
          list3.push(<option>{`${timevalues[j]}`}</option>);
        }
      }
    }
    return list3;
  }

  handleRegister = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      const res = await this.store.form.update();

      // if (!res) {
      //   return;
      // }
    }
  };

  termsandcondition() {
    return "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected there to (collectively, the “Site”)";
  }

  handleChange2 = async (event) => {
    if (event.target.value === "Choose Timezone") {
      this.store.form.timezoneselected = false;
    } else {
      this.store.form.timezone = event.target.value;
      this.store.form.timezoneselected = true;
    }
  };

  handleChange3 = async (event) => {
    if (event.target.value === "Choose Data Required") {
      this.store.form.customerdataselected = false;
    } else {
      this.store.form.customerdata = event.target.value;
      this.store.form.customerdataselected = true;
    }
  };

  render() {
    return (
      <Form onKeyDown={(e) => this.handleRegister(e)}>
        <FormErrorMessage form={this.store.form}></FormErrorMessage>

        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="UserName"
              label="Name"
              field={this.store.form.userName}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="email"
              label="Email"
              field={this.store.form.email}
              disabled={true}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="password"
              label="Password"
              field={this.store.form.password}
              disabled={true}
            />
          </Col>
          <Col>
            <MaskValidatedInputGroup
              fieldName="c-password"
              label="Confirm Password"
              field={this.form.confimPass}
              disabled={true}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="CompanyName"
              label="Company Name"
              field={this.store.form.company}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <ValidatedInputGroup
              fieldName="LocationUrl"
              label="Location URL"
              field={this.store.form.locationurl}
            />
          </Col>
          <Col>
            <Form.Label style={{ fontSize: "13px", fontWeight: "normal" }}>
              TimeZone
            </Form.Label>
            <FormControl as="select" onChange={this.handleChange2}>
              <option>{this.store.form.timezone}</option>
              <option>Choose Timezone</option>
              {this.listing()}
            </FormControl>
          </Col>
          <Col>
            <Form.Label style={{ fontSize: "13px", fontWeight: "normal" }}>
              Require Customer Data
            </Form.Label>
            <FormControl as="select" onChange={this.handleChange3}>
              <option>{this.store.form.customerdata}</option>
              <option>Choose Data Required</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </FormControl>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="Address1"
              label="Address1"
              field={this.store.form.address1}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="Address1"
              label="Address2"
              field={this.store.form.address2}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="city"
              label="City"
              field={this.store.form.city}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="state"
              label="State"
              field={this.store.form.state}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="phonenumber"
              label="Phone Number"
              field={this.store.form.phonenumber}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="Zipcode"
              label="Zip/Postal code"
              field={this.store.form.zipcode}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroup
              disabled
              as="textarea"
              rows="5"
              label="Terms and Conditions for customers"
              placeholder={this.termsandcondition()}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className="text-center">
            <Button onClick={() => this.store.form.back()} variant="secondary">
              Back
            </Button>{" "}
            <Button
              onClick={() => this.store.form.update()}
              variant="success"
              disabled={this.store.form.isWorking}
            >
              {this.store.form.isWorking ? (
                <span>
                  Updating...
                  <i className="fa fa-refresh fa-spin" />
                </span>
              ) : (
                "Update"
              )}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default EditLocationComponent;
