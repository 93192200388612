import React, { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Alert,
  Card,
  Navbar,
  Nav,
  Modal,
} from "react-bootstrap";
import { ValidatedInputGroup } from "../forms/ValidatedInputGroup";
import { VisitorForm } from "./VisitorRegisterForm";
import { ValidatedDatePickerGroup } from "../forms/ValidatedDatePickerGroup";
import { observer } from "mobx-react";
import { portals } from "mobx-portals";
import TermsModal from "./TermsModdal";
import logo from "../Gravitycareslogo.jpg";
import { ReCaptcha, loadReCaptcha, recaptcha } from "react-recaptcha-v3";
import Terms from "./terms";
import Conditions from "./conditions";
import covidlogsterms from "../../src/GravityTermsofService.pdf";
import covidlogsconditions from "../../src/GravityPrivacyPolicy.pdf";

export interface VisitorFormProps {
  form: VisitorForm;
}

export interface ModalProps {
  unmount?: (data?: any) => void;
  form?: VisitorForm;
}

@observer
export class VisitorFormComponent extends React.Component<
  VisitorFormProps,
  {}
> {
  constructor(props: VisitorFormProps) {
    super(props);
  }

  handleSubmit = async () => {
    const data = await this.props.form.register();

    if (!data) {
      this.props.form.setError("Fill all fields");
    } else {
      if (!data.LocationUrl) {
        window.location.assign("/success");
      } else {
        window.location.assign(data.LocationUrl);
      }
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      this.props.form.termsError = false;
      this.props.form.terms = e.target.checked;
    } else {
      this.props.form.terms = e.target.checked;
    }
  };

  verifyCallback = async (token) => {
    // Here you will get the final token!!!
    // console.log(token, "verifycallback");
    if (token) {
      this.props.form.captcha = true;
      this.props.form.captchaError = false;
    } else {
      this.props.form.captchaError = true;
      this.props.form.captcha = false;
    }
  };

  componentDidMount() {
    loadReCaptcha(
      "6LeZLa4ZAAAAAD8OUM-uSavqlj0bngNI4rM4Bupp",
      this.verifyCallback
    );
  }

  gravityconditions() {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    window.open(covidlogsconditions, "_blank", params);
  }

  handleClose = () => false;

  render() {
    const { form } = this.props;

    return (
      <div>
        {/* <Navbar>
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="110"
              height="30"
              className="d-inline-block p-1 align-top"
            />
          </Navbar.Brand>
        </Navbar> */}
        {(!form.isWorking && (
          <div className="container bg-white p-15 mt-3">
            <Row className="justify-content-center">
              <span className="text-center h4"> {form.companyName} </span>
            </Row>
            <Row className="justify-content-center">
              <span className="h6 text-center">{`${
                form.location && form.location.Address1
              },${form.location && form.location.City}`}</span>
            </Row>

            <Modal
              show={form.termsModal}
              onHide={() => (form.termsModal = !form.termsModal)}
              size="lg"
            >
              <Modal.Header closeButton>
                <h4 style={{ justifyContent: "center" }}>
                  Gravity Terms of Service
                </h4>
              </Modal.Header>
              {/* <Modal.Body>{form.termstext}</Modal.Body> */}
              <Terms />
              <Modal.Footer style={{ justifyContent: "center" }}>
                <Button
                  variant="secondary"
                  onClick={() => (form.termsModal = !form.termsModal)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={form.termsModal1}
              onHide={() => (form.termsModal1 = !form.termsModal1)}
              size="lg"
            >
              <Modal.Header closeButton>
                <h4 style={{ justifyContent: "center" }}>
                  Gravity Privacy Policy
                </h4>
              </Modal.Header>
              {/* <Modal.Body>{form.termstext}</Modal.Body> */}
              <Conditions />
              <Modal.Footer style={{ justifyContent: "center" }}>
                <Button
                  variant="secondary"
                  onClick={() => (form.termsModal1 = !form.termsModal1)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Card
              className="r-card"
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <Form>
                {form.hasError && (
                  <Alert variant="danger">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{form.errorMessage}</p>
                  </Alert>
                )}

                <Form.Row className="mb-2 justify-content-center">
                  <Col md={12}>
                    <ValidatedInputGroup
                      fieldName="Phone No"
                      label="Phone No"
                      field={form.phoneNo}
                    ></ValidatedInputGroup>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-2 justify-content-center">
                  <Col md={12}>
                    <ValidatedInputGroup
                      fieldName="email"
                      label="Email"
                      field={form.email}
                    ></ValidatedInputGroup>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-2 justify-content-center">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Check
                        onChange={this.handleChange}
                        required
                        label={
                          <span>
                            I agree to the{" "}
                            <Button
                              variant="link"
                              onClick={() => (form.termsModal = true)}
                              className="text-primary p-0"
                            >
                              Terms
                            </Button>{" "}
                            and{" "}
                            <Button
                              variant="link"
                              onClick={() => (form.termsModal1 = true)}
                              // onClick={() => this.gravityconditions()}
                              className="text-primary p-0"
                            >
                              Conditions
                            </Button>
                          </span>
                        }
                        feedback="You must agree before submitting."
                      />

                      {form.termsError && (
                        <Form.Text className="text-danger">
                          Please Check the terms and conditions
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-2 justify-content-center">
                  <div>
                    <Button variant="success" onClick={this.handleSubmit}>
                      Submit
                    </Button>
                    {"  "}
                    {form.skipsubmit ? (
                      " "
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={() =>
                          window.location.assign(`${form.MainURL}`)
                        }
                      >
                        Skip To Menu
                      </Button>
                    )}
                  </div>
                  <ReCaptcha
                    sitekey="6LeZLa4ZAAAAAD8OUM-uSavqlj0bngNI4rM4Bupp"
                    action="main"
                    verifyCallback={this.verifyCallback}
                  />
                </Form.Row>
                <Form.Row className="mb-2  justify-content-center">
                  <Form.Text>
                    <span>
                      Powered by{" "}
                      <a target="_blank" href="https://www.gravitypayments.com">
                        Gravity Payments
                      </a>{" "}
                      and{" "}
                    </span>
                  </Form.Text>
                  <Form.Text>
                    {" "}
                    <span>
                      <a target="_blank" href="https://www.corezi.com">
                        Corezi.com
                      </a>
                    </span>
                  </Form.Text>
                </Form.Row>
              </Form>
            </Card>
          </div>
        )) || (
          <div className="d-flex p-5 container justify-content-center">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export function termModal(args: ModalProps): Promise<string> {
  const res = portals.render({
    id: "terms_condition",
    component: <TermsModal {...args} />,
  });
  return res.waitForUnmount;
}

export default VisitorForm;
