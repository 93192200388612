import React from "react";
import AuthService, { QR } from "../Auth/AuthService";
import { Card, Navbar, Nav, Button } from "react-bootstrap";
import logo from "../Gravitycareslogo.jpg";
import { observer, inject } from "mobx-react";

export interface QRdownloadpageProps {
  user?: any;
}

@inject("user")
@observer
class QRdownloadpage extends React.Component<QRdownloadpageProps> {
  constructor(props: QRdownloadpageProps) {
    super(props);
  }
  render() {
    console.log(
      `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
        QR + this.props.user.LocationID
      }`
    );
    return (
      <div className="width  container bg-white p-5">
        <Card
          className="text-center justify-content-center"
          style={{ maxWidth: "50rem" }}
        >
          <Card.Header>
            <h5>Welcome to {this.props.user.LocationName}</h5>
          </Card.Header>
          <Card.Body>
            <Card.Title>Dear Customers</Card.Title>
            <Card.Title>Scan the QR Code</Card.Title>
            <Card.Text>This will help us in getting a New Experience</Card.Text>

            <Card.Img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${
                QR + this.props.user.LocationID
              }`}
            />
          </Card.Body>
          <Card.Footer className="text-muted">
            <Navbar bg="light" variant="dark">
              <Navbar.Brand>
                <img
                  alt=""
                  src={logo}
                  width="100"
                  height="30"
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Nav.Item>Powered by Gravity Payments and Corezi.com</Nav.Item>
              </Navbar.Collapse>
            </Navbar>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default QRdownloadpage;
