import React from "react";
import { SuperAdminLocationLogsStore } from "./SuperAdminLocationlogsstore";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { observer } from "mobx-react";
import overlayFactory from "react-bootstrap-table2-overlay";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { Nav } from "react-bootstrap";

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

export interface SuperAdminLocationLogsListProps {
  store: SuperAdminLocationLogsStore;
}

@observer
class SuperAdminLocationLogsList extends React.Component<
  SuperAdminLocationLogsListProps,
  {}
> {
  constructor(props: SuperAdminLocationLogsListProps) {
    super(props);
  }

  render() {
    const { store } = this.props;
    const columns = [
      {
        dataField: "PhoneNumber",
        text: "Phone No",
        align: "center",
        headerAlign: "center",
        headerStyle: () => {
          return { width: 150 };
        },
      },
      {
        dataField: "EmailId",
        text: "Email ID",
        align: "center",
        headerAlign: "center",
        headerStyle: () => {
          return { width: 150 };
        },
      },
      {
        dataField: "VisitInTime",
        text: "In-Time",
        align: "center",
        headerAlign: "center",
        headerStyle: () => {
          return { width: 200 };
        },
        formatter: (out) => <span>{out.toString()}</span>,
      },
    ];

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button
            className="btn btn-primary"
            style={{ float: "right" }}
            onClick={handleClick}
          >
            Export to CSV
          </button>
        </div>
      );
    };

    return (
      <div id="logs">
        <ToolkitProvider
          noDataIndication="No data"
          data={store.records}
          columns={columns}
          loading={store.isLoading}
          search
          exportCSV={{
            fileName: "covidadminlocationvisitlogs.csv",
          }}
        >
          {(props) => (
            <div>
              <MyExportCSV {...props.csvProps} />

              <h5>Search</h5>
              <SearchBar {...props.searchProps} />

              <BootstrapTable
                {...props.baseProps}
                striped
                keyField="logstable"
                noDataIndication="No data"
                pagination={paginationFactory()}
                bootstrap4
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(204,255,255,0.2)",
                })}
                hover
                condensed
              ></BootstrapTable>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default SuperAdminLocationLogsList;
