import { BaseForm } from "../../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import { PasswordValidatedField } from "../../forms/validated-fields/PasswordValidatedField";
import ApiService from "../../Auth/ApiService";
import { computed, observable } from "mobx";

export class ChangePswdForm extends BaseForm {
  oldpassword = new PasswordValidatedField({ required: true });
  password = new PasswordValidatedField({ required: true });
  confimPass = new PasswordValidatedField({ required: true });
  confirmPasserror: boolean = false;

  constructor() {
    super();
    this.oldpassword.handleChange("");
  }

  @computed
  get allFields(): ValidatedField[] {
    return [this.oldpassword, this.password];
  }

  @computed
  get isValid(): boolean {
    return this.allFields.every((field) => field.isValid);
  }

  async pswdverify(pswd1, pswd2) {
    if (pswd1 !== pswd2) {
      this.confimPass.setError("Password Mismatch");
      this.confirmPasserror = false;
    } else {
      this.confirmPasserror = true;
    }
  }
  back() {
    const rolemake: any = JSON.parse(
      localStorage.getItem("user").split(",")[1].split(":")[1]
    );

    if (rolemake === "superadmin") {
      window.location.assign("/superadmindashboard");
    } else {
      window.location.assign("/admindashboard");
    }
  }

  async screentime() {
    return window.location.reload();
  }

  async register() {
    if (!this.isValid) {
      this.allFields.forEach((field) => field.handleSubmit());
      return;
    }

    const emailid = JSON.parse(
      localStorage.getItem("user").split(",")[4].split(":")[1]
    );

    if (!this.confirmPasserror) {
      this.confimPass.setError("Password Mismatch");
      return;
    }
    const body = {
      user: {
        EmailId: emailid,
        OldPassword: this.oldpassword.value,
        NewPassword: this.password.value,
      },
    };

    try {
      this.setWorking();
      const pswdregister: any = await ApiService.post("User/pswdchange", body);

      if (!pswdregister || !pswdregister.UserID) {
        this.setError(pswdregister);
      } else {
        this.setSuccess(pswdregister.message);
        window.setInterval(this.screentime, 5000);
        // window.location.assign("/adminpswdsuccesspage");
      }
    } catch (e) {
      this.setError(e);
    }
  }
}
