import { observable, computed } from "mobx";
import ApiService from "../../Auth/ApiService";

export class LocationListStroe {
  @observable _records: Array<any>;
  @observable isLoading: boolean = false;
  constructor() {
    this._records = [];
  }

  @computed
  get records(): Text[] {
    return Array.from(this._records.values());
  }

  async load() {
    try {
      this.isLoading = !this.isLoading;
      const user = JSON.parse(localStorage.getItem("user"));
      const data = await ApiService.getWH("getlogbylocation", {
        params: { LocationID: user.LocationID },
      });

      if (data) {
        this._records = data;
        this.isLoading = !this.isLoading;
      }
    } catch (e) {
      this.isLoading = !this.isLoading;
    }
  }
}
