import { observable, computed } from "mobx";
import ApiService from "../../Auth/ApiService";

export class SuperAdminLocationLogsStore {
  @observable _records: Array<any>;
  @observable isLoading: boolean = false;
  @observable locationame: string;
  constructor() {
    this._records = [];
  }

  @computed
  get records(): Text[] {
    return Array.from(this._records.values());
  }

  async load(locationID: string) {
    try {
      this.isLoading = !this.isLoading;
      const data = await ApiService.getWH("superadmin/getlogbylocation", {
        params: { LocationID: locationID },
      });

      if (data) {
        this._records = data.visitlogs;

        this.locationame = data.LocationName;
        var existing = localStorage.getItem("user");

        // If no existing data, create an array
        // Otherwise, convert the localStorage string to an array
        existing = existing ? JSON.parse(existing) : {};

        // Add new data to localStorage Array
        existing["LocationName"] = data.LocationName;
        existing["LocationID"] = data.LocationID;

        // Save back to localStorage
        localStorage.setItem("user", JSON.stringify(existing));
        localStorage.setItem("user", JSON.stringify(existing));

        this.isLoading = !this.isLoading;
      }
    } catch (e) {
      this.isLoading = !this.isLoading;
    }
  }
}
