import { observer } from 'mobx-react';
import { ValidatedField } from 'mobx-validated-field';
import * as React from 'react';
import { IInputGroupProps, InputGroup } from './InputGroup';
import { FontAwesomeIcon } from '../common/FontAwesomeIcon';

export interface ISecureValidatedInputGroupProps {
    field: ValidatedField;
    fieldName: string;
    className?: string;
    helpText?: string;
    label?: string | React.ReactNode;
    prepend?: string | React.ReactNode;
    append?: string | React.ReactNode;
    as?: any;
    autoComplete?: string;
    maxLength?: number;
    min?: number;
    max?: number;
    minLength?: number;
    type?: string;
    rows?: string;
    disabled?: boolean;
    onBlur?: (e: React.FocusEvent) => void;
}

export const MaskValidatedInputGroup = observer((props: ISecureValidatedInputGroupProps) => {
    const { field, fieldName } = props;
    const [showHide, setShowHide] = React.useState('password');

    const show = () => {
        if (showHide === 'password') {
            setShowHide('input');
        } else {
            setShowHide('password');
        }
    };

    const handleChange = (e: React.ChangeEvent<any>) => {
        if (props.disabled) {
            e.preventDefault();
            return;
        }
        const value = e.target.value;
        field.handleChange(value);
    };

    const icon = () => {
        if (showHide === 'input') {
            return <FontAwesomeIcon onClick={show} icon='eye' />;
        } else {
            return <FontAwesomeIcon onClick={show} icon='eye-slash' />;
        }
    };

    const handleBlur = (e: React.FocusEvent) => {
        field.handleSubmit();
        props.onBlur && props.onBlur(e);
    };

    const inputProps: IInputGroupProps = {
        controlId: `form:${fieldName}`,
        errorMessage: field.firstErrorMessage,
        helpText: props.helpText,
        className: props.className,
        isInvalid: field.hasError,
        isValid: field.value && field.isValid,
        label: props.label,
        prepend: props.prepend,
        append: icon(),
        maxLength: props.maxLength,
        minLength: props.minLength,
        autoComplete: props.autoComplete,
        min: props.min,
        max: props.max,
        onBlur: handleBlur,
        onChange: handleChange,
        placeholder: field.placeholder,
        type: showHide,
        value: field.value,
        rows: props.rows,
        as: props.as,
        disabled: props.disabled
    };

    return <InputGroup {...inputProps} />;
});
