import React from "react";
import ChangePswdComponent from "../locationAdmin/Changepswd/ChangePswdFormComponent";
import { Navbar } from "react-bootstrap";
import logo from "../Gravitycareslogo.jpg";

export interface ChangePswdPageProps {}

export interface ChangePswdPageState {}

class ChangePswdPage extends React.Component<
  ChangePswdPageProps,
  ChangePswdPageState
> {
  constructor(props: ChangePswdPageProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Navbar className="h-25">
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="150"
              height="70"
              className="d-inline-block p-2 align-top"
            />
          </Navbar.Brand>
        </Navbar>
        <h4 className="text-center mt-4">
          Location Admin Password Change Form
        </h4>
        <div className="card register p-4 container">
          <ChangePswdComponent />
        </div>
      </div>
    );
  }
}

export default ChangePswdPage;
