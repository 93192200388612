import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

export interface IFormGroupProps {
    children?: React.ReactNode;
    className?: string;
    controlId?: string;
    errorMessage?: string;
    helpText?: string;
    label?: string | React.ReactNode;
    prepend?: string | React.ReactNode;
    append?: string | React.ReactNode;
    prependDownDown?: string | React.ReactNode;
    selectedPrePend?: string;
    prependOnChange?: (val: string) => void;
}

export const FormGroup = observer((props: IFormGroupProps) => {
    const renderLabel = () => {
        if (!props.label) {
            return null;
        }

        return <Form.Label className='small font-weight-semibold'>{props.label}</Form.Label>;
    };

    const handleDropDownChange = (val: string) => {
        props.prependOnChange(val);
    };

    const prependDowndown = () => {
        if (!props.prependDownDown) {
            return null;
        }
        const items = (props.prependDownDown as string).split(',');
        return (
            <DropdownButton
                as={InputGroup.Prepend}
                variant='outline-light'
                title={props.selectedPrePend ? props.selectedPrePend : items[0]}
                id='input-group-dropdown-2'
                onSelect={handleDropDownChange}
                className='drop-down-button'
            >
                {items.map(item => (
                    <Dropdown.Item
                        key={item}
                        eventKey={item}
                        active={props.selectedPrePend ? props.selectedPrePend == item : items[0] == item}
                    >
                        {item}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    };

    const append = () => {
        if (!props.append) {
            return null;
        }
        return (
            <InputGroup.Append>
                <InputGroup.Text id='basic-addon2'>{props.append}</InputGroup.Text>
            </InputGroup.Append>
        );
    };

    const renderFormText = () => {
        if (!props.helpText && !props.errorMessage) {
            return null;
        }

        const className = props.errorMessage ? 'text-danger' : 'text-muted';
        const text = props.errorMessage ? props.errorMessage : props.helpText;

        return <Form.Text className={className}>{text}</Form.Text>;
    };

    return (
        <Form.Group className={props.className} controlId={props.controlId}>
            {renderLabel()}
            <InputGroup>
                {prepend(props.prepend)}
                {prependDowndown()}
                {props.children}
                {append()}
            </InputGroup>
            {renderFormText()}
        </Form.Group>
    );
});

export const prepend = prependVal => {
    if (!prependVal) {
        return null;
    }
    return (
        <InputGroup.Prepend>
            <InputGroup.Text id='inputGroupPrepend'>{prependVal}</InputGroup.Text>
        </InputGroup.Prepend>
    );
};
