import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

type FlipProp = 'horizontal' | 'vertical' | 'both';
type SizeProp = 'xs' | 'lg' | 'md' | 'xl' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
type PullProp = 'left' | 'right';
type RotateProp = 90 | 180 | 270;
type IconPrefix = 'fas' | 'fab' | 'far' | 'fal' | 'fad';
export type IconProp = { prefix: IconPrefix; iconName: string } | string;

export interface IFontAwesomeIconProps {
    icon: IconProp;
    className?: string;
    spin?: boolean;
    pulse?: boolean;
    border?: boolean;
    fixedWidth?: boolean;
    inverse?: boolean;
    listItem?: boolean;
    flip?: FlipProp;
    size?: SizeProp;
    pull?: PullProp;
    rotation?: RotateProp;
    swapOpacity?: boolean;
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FontAwesomeIcon = observer((props: IFontAwesomeIconProps) => {
    // Normalize icon arguments
    const normalizeIconArgs = (icon: IconProp): IconProp => {
        // if the icon is null, there's nothing to do
        if (icon === null) {
            return null;
        }
        // if the icon is an object and has a prefix and an icon name, return it
        if (typeof icon === 'object' && icon.prefix && icon.iconName) {
            icon.iconName = faConcat(icon.iconName);
            return icon;
        }
        // if it's an array with length of two
        if (Array.isArray(icon) && icon.length === 2) {
            // use the first item as prefix, second as icon name
            return {
                prefix: icon[0],
                iconName: faConcat(icon[1])
            };
        }
        // if it's a string, use it as the icon name
        if (typeof icon === 'string') {
            return {
                prefix: 'fas',
                iconName: faConcat(icon)
            };
        }
    };

    const faConcat = (iconName: string) => {
        return iconName.includes('fa-') ? iconName : 'fa-'.concat(iconName);
    };

    const iconVal: any = normalizeIconArgs(props.icon);

    const className = classNames(
        {
            'fa-spin': props.spin,
            'fa-pulse': props.pulse,
            'fa-fw': props.fixedWidth,
            'fa-inverse': props.inverse,
            'fa-border': props.border,
            'fa-li': props.listItem,
            'fa-swap-opacity': props.swapOpacity,
            'fa-flip-horizontal': props.flip === 'horizontal' || props.flip === 'both',
            'fa-flip-vertical': props.flip === 'vertical' || props.flip === 'both',
            [`fa-${props.size}`]: props.size,
            [`fa-rotate-${props.rotation}`]: props.rotation,
            [`fa-pull-${props.pull}`]: props.pull
        },
        props.className,
        iconVal.prefix,
        iconVal.iconName
    );

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        props.onClick && props.onClick(e);
    };

    return <i className={className} onClick={handleClick} title={props.title ? props.title : ''} aria-hidden='true'></i>;
});
