import { BaseForm } from "../../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import { EmailValidatedField } from "../../forms/validated-fields/EmailValidatedField";
import { PasswordValidatedField } from "../../forms/validated-fields/PasswordValidatedField";
import { NameValidatedField } from "../../forms/validated-fields/NameValidatedField";
import { PhoneValidatedField } from "../../forms/validated-fields/PhoneValidatedField";
import ApiService from "../../Auth/ApiService";
import { computed } from "mobx";

export class RegisterAdminForm extends BaseForm {
  userName = new ValidatedField({ required: true });
  email = new EmailValidatedField({ required: true });
  password = new PasswordValidatedField({ required: true });
  confimPass = new PasswordValidatedField({ required: true });
  phonenumber = new PhoneValidatedField({ required: true });
  confirmPasserror: boolean = false;

  constructor() {
    super();
    this.userName.handleChange("");
  }

  @computed
  get allFields(): ValidatedField[] {
    return [
      this.userName,
      this.password,
      this.phonenumber,
      this.confimPass,
      this.email,
    ];
  }
  @computed
  get isValid(): boolean {
    return this.allFields.every((field) => field.isValid);
  }

  async pswdverify(pswd1, pswd2) {
    if (pswd1 !== pswd2) {
      this.confimPass.setError("Password Mismatch");
      this.confirmPasserror = false;
    } else {
      this.confirmPasserror = true;
    }
  }

  async emailvalid(emaildata) {
    const data = await ApiService.get("checkemail/" + `${emaildata}`);
    if (data) {
      this.email.setError("This email already exist");
      return false;
    }
  }

  back() {
    const rolemake: any = JSON.parse(
      localStorage.getItem("user").split(",")[1].split(":")[1]
    );

    if (rolemake === "superadmin") {
      window.location.assign("/superadmindashboard");
    } else {
      window.location.assign("/admindashboard");
    }
  }

  async register() {
    if (!this.isValid) {
      this.allFields.forEach((field) => field.handleSubmit());
      return;
    }

    if (!this.confirmPasserror) {
      this.confimPass.setError("Password Mismatch");
      return;
    }

    const body = {
      user: {
        UserName: this.userName.value,
        EmailId: this.email.value,
        Password: this.password.value,
        PhoneNumber: this.phonenumber.value,
        RoleID: "superadmin",
      },
    };
    try {
      this.setWorking();
      const register: any = await ApiService.post(
        "User/createUsersuperadmin",
        body
      );

      if (!register || !register.UserID) {
        this.setError(register);
        // console.log(this.errorMessage);
      } else {
        window.location.assign("/adminsuccesspage");
      }
    } catch (e) {
      this.setError(e);
    }
  }
}
