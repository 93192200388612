import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL } from "./AuthService";
import authHeader from "./AuthHeader";

class ApiService {
  async getWH(url, body?: AxiosRequestConfig) {
    body.headers = authHeader();
    const data = await axios
      .get(API_URL + url, body)
      .then((res: AxiosResponse) => {
        if (res) {
          return res.data;
        }
        return res.statusText;
      });
    return data;
  }
  async get(url, body?: AxiosRequestConfig) {
    const data = await axios
      .get(API_URL + url, body)
      .then((res: AxiosResponse) => {
        if (res) {
          return res.data;
        }
        return res.statusText;
      });
    return data;
  }
  async post(url, body: any) {
    const data = await axios
      .post(API_URL + url, body)
      .then((res: AxiosResponse) => {
        if (res) {
          return res.data;
        }
      });
    return data;
  }
  async put(url: string, id: string, body?: AxiosRequestConfig) {
    const data = await axios
      .put(API_URL + url, id, body)
      .then((res: AxiosResponse) => {
        if (res) {
          return res.data;
        }
      });
    return data;
  }
}

export default new ApiService();
