import React from "react";
import EditLocationComponent from "./superadmin-location-list/EditLocationComponent";
import { Navbar } from "react-bootstrap";
import logo from "../Gravitycareslogo.jpg";
import { RouteChildrenProps } from "react-router-dom";

export interface EditLocationPageProps {}

export interface EditLocationPageState {}

class EditLocationPage extends React.Component<
  EditLocationPageProps,
  EditLocationPageState
> {
  constructor(props: EditLocationPageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Navbar className="h-25">
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="150"
              height="70"
              className="d-inline-block p-2 align-top"
            />
          </Navbar.Brand>
        </Navbar>
        <h4 className="text-center mt-4">Edit Location Form</h4>
        <div className="card register p-4 container">
          <EditLocationComponent />
        </div>
      </div>
    );
  }
}

export default EditLocationPage;
