import * as React from "react";
import {
  Form,
  Alert,
  Col,
  Button,
  InputGroup,
  FormControl,
  Navbar,
  Nav,
} from "react-bootstrap";
import { observer } from "mobx-react";
import { ForgetPswdPageForm } from "./ForgetPswdPageForm";
import logo from "../Gravitycareslogo.jpg";
import FormErrorMessage from "../forms/FormErrorMesssage";
import { ValidatedInputGroup } from "../forms/ValidatedInputGroup";
import { MaskValidatedInputGroup } from "../forms/MaskValidatedInputGroup";
import FormSuccessMessage from "../forms/FormSuccessMessage";

export interface ForgetPswdPageProps {}

export interface ForgetPswdPageState {}

@observer
class ForgetPswdPage extends React.Component<
  ForgetPswdPageProps,
  ForgetPswdPageState
> {
  form1: ForgetPswdPageForm;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      OTP: "",
    };
    this.form1 = new ForgetPswdPageForm();
  }

  handleEmail = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await this.emailsent();
    }
  };

  handleOTP = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await this.otpsent();
    }
  };

  handlepswd = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      this.form1.pswdverify(
        this.form1.password.value,
        this.form1.confimPass.value
      );

      await this.form1.newpswdregister();
    }
  };

  emailsent = async () => {
    this.form1.email = this.state["email"];
    const emailsent1 = await this.form1.OtpSent();

    if (!emailsent1) {
      this.form1.emailbutton = false;
      this.form1.nextsection = false;
    } else {
      this.form1.usersid = emailsent1.UserID;
      this.form1.nextsection = true;
      this.form1.emailbutton = true;
    }
  };

  otpsent = async () => {
    this.form1.OTP = this.state["OTP"];
    const emailsent1 = await this.form1.verifyOTP();

    if (!emailsent1) {
      this.form1.otpbutton = false;
      this.form1.nextsection1 = false;
    } else {
      this.form1.nextsection1 = true;
      this.form1.otpbutton = true;
    }
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChange1 = (event) => {
    this.setState({ OTP: event.target.value });
  };

  render() {
    const { form1 } = this;

    return (
      <div>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand onClick={() => window.location.reload()}>
            <img
              alt=""
              src={logo}
              width="120"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <Button
                variant="secondary"
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Back
              </Button>
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
        <h5 className="text-center mt-4">Password Recovery Form</h5>
        <div className="container card bg-white p-5 mt-5">
          <FormErrorMessage form={form1}></FormErrorMessage>
          <FormSuccessMessage form={this.form1}></FormSuccessMessage>

          <Form onKeyDown={(e) => this.handleEmail(e)}>
            <Form.Row className="mb-2">
              <Col>
                <InputGroup>
                  <Form.Control
                    placeholder="Enter Registered Email"
                    onChange={this.handleChange}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="success"
                      onClick={() => this.emailsent()}
                      // disabled={this.form1.isWorking}
                    >
                      {this.form1.emailbutton ? "Resend OTP" : "Send OTP"}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Row>
          </Form>

          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>

          {this.form1.nextsection ? (
            <Form onKeyDown={(e) => this.handleOTP(e)}>
              <Form.Row className="mb-2">
                <Col>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter One Time Password"
                      onChange={this.handleChange1}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="success"
                        onClick={() => this.otpsent()}
                        disabled={this.form1.otpbutton}
                      >
                        {this.form1.otpbutton ? "Verified" : "Submit OTP"}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>{" "}
            </Form>
          ) : (
            ""
          )}

          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>
          <Form.Row className="mb-2"></Form.Row>

          {this.form1.nextsection1 ? (
            <Form onKeyDown={(e) => this.handlepswd(e)}>
              <Form.Row className="mb-2">
                <Col>
                  <MaskValidatedInputGroup
                    fieldName="password1"
                    label="New Password"
                    field={this.form1.password}
                  ></MaskValidatedInputGroup>
                </Col>

                <Col>
                  <MaskValidatedInputGroup
                    fieldName="c-password"
                    label="Confirm New Password"
                    field={this.form1.confimPass}
                    onBlur={() =>
                      this.form1.pswdverify(
                        this.form1.password.value,
                        this.form1.confimPass.value
                      )
                    }
                  ></MaskValidatedInputGroup>
                </Col>
              </Form.Row>{" "}
              <Form.Row>
                <Col className="text-center">
                  <Button
                    onClick={() => this.form1.newpswdregister()}
                    variant="success"
                    disabled={this.form1.pswdsetbutton}
                  >
                    {this.form1.pswdsetbutton
                      ? "Creating New Password"
                      : "Create New Password"}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ForgetPswdPage;
