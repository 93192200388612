import React from "react";
import LocationList from "./location-list/LocationListPage";
import { Navbar, Button, Nav, Badge } from "react-bootstrap";
import AuthService, { QR } from "../Auth/AuthService";
import logo from "../Gravitycareslogo.jpg";
import { LocationListStroe } from "./location-list/LocationListStore";
import { observer, inject } from "mobx-react";

export interface LocationAdminPageProps {
  user?: any;
}

export interface LocationAdminPageState {}

@inject("user")
@observer
class LocationAdminPage extends React.Component<
  LocationAdminPageProps,
  LocationAdminPageState
> {
  store: LocationListStroe;
  constructor(props: LocationAdminPageProps) {
    super(props);
    this.state = {};
    this.store = new LocationListStroe();
  }

  componentDidMount() {
    this.store.load();
  }
  URL = () => {
    if (!this.props.user.LocationID) {
      return;
    }
    const data = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
      QR + this.props.user.LocationID
    }`;
    // console.log(data);

    return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
      QR + this.props.user.LocationID
    }`;
  };

  render() {
    console.log(this.URL());
    return (
      <div className="hieght width-fit">
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/admindashboard">
            <img
              alt=""
              src={logo}
              width="150"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />

          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <Badge
                variant="dark"
                style={{ fontSize: 22, fontWeight: "normal" }}
              >
                Welcome {this.props.user.LocationName}
              </Badge>{" "}
              <Button
                onClick={() => {
                  window.open("/QRdownloadpage");
                  // window.print(); Prints entire page
                }}
              >
                <i className="fas fa-download" /> Download QR
              </Button>
              {"  "}
              <Button
                onClick={() => {
                  window.location.assign("/adminpswdchange");
                }}
              >
                Change Password
              </Button>
              {"  "}
              <Button
                onClick={() => {
                  AuthService.logout();
                  window.location.replace("/login");
                }}
              >
                Logout
              </Button>
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
        <div className=" p-5">
          <LocationList store={this.store}></LocationList>
        </div>
        <div className=" text-center justify-content-center">
          <h3>QR code For Your Customer's Log Registration Form</h3>
          <img
            alt="Qr"
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
              QR + this.props.user.LocationID
            }`}
            width="150"
            height="150"
            className="d-inline-block align-top"
          />
        </div>

        <Navbar className="justify-content-center" fixed="bottom">
          <span>Powered by Gravity Payments and Corezi.com</span>
        </Navbar>
      </div>
    );
  }
}

export default LocationAdminPage;
