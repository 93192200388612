import { BaseForm } from "../../forms/BaseForm";
import { ValidatedField } from "mobx-validated-field";
import { EmailValidatedField } from "../../forms/validated-fields/EmailValidatedField";
import { PasswordValidatedField } from "../../forms/validated-fields/PasswordValidatedField";
import { NameValidatedField } from "../../forms/validated-fields/NameValidatedField";
import { NumberOnlyValidatedField } from "../../forms/validated-fields/NumberOnlyValidatedField";
import { PostalCodeValidatedField } from "../../forms/validated-fields/PostalCodeValidatedField";
import { PhoneValidatedField } from "../../forms/validated-fields/PhoneValidatedField";
import ApiService from "../../Auth/ApiService";
import { computed } from "mobx";

export class LocationRegisterForm extends BaseForm {
  userName = new ValidatedField({ required: true });
  email = new EmailValidatedField({ required: true });
  password = new PasswordValidatedField({ required: true });
  confimPass = new PasswordValidatedField({ required: true });
  company = new NameValidatedField({ required: true });
  address1 = new ValidatedField({ required: true });
  address2 = new ValidatedField();
  state = new ValidatedField({ required: true });
  city = new ValidatedField({ required: true });
  phonenumber = new PhoneValidatedField({ required: true });
  zipcode = new PostalCodeValidatedField({ required: true });
  // time = new NumberOnlyValidatedField({ required: true });
  // terms = new ValidatedField({ required: true });
  locationurl = new ValidatedField({ required: true });
  timezone = new ValidatedField({ required: true });
  timezoneselected: boolean = false;
  confirmPasserror: boolean = false;
  customerdata: string;
  customerdataboolean: boolean;
  customerdataselected: boolean = false;

  constructor() {
    super();
    this.userName.handleChange("");
  }

  @computed
  get allFields(): ValidatedField[] {
    return [
      this.userName,
      this.password,
      this.company,
      this.city,
      this.address1,
      this.address2,
      this.state,
      this.phonenumber,
      this.zipcode,
      this.confimPass,
      // this.time,
      // this.terms,
      this.email,
      this.locationurl,
    ];
  }

  @computed
  get isValid(): boolean {
    return this.allFields.every((field) => field.isValid);
  }

  @computed
  get timezonevalid(): boolean {
    return this.timezoneselected;
  }

  @computed
  get customerdatavalid(): boolean {
    return this.customerdataselected;
  }

  async pswdverify(pswd1, pswd2) {
    if (pswd1 !== pswd2) {
      this.confimPass.setError("Password Mismatch");
      this.confirmPasserror = false;
    } else {
      this.confirmPasserror = true;
    }
  }

  termsandcondition() {
    return "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected there to (collectively, the “Site”)";
  }

  async emailvalid(emaildata) {
    const data = await ApiService.get("checkemail/" + `${emaildata}`);
    if (data) {
      this.email.setError("This email already exist");
      return false;
    }
  }

  back() {
    const rolemake: any = JSON.parse(
      localStorage.getItem("user").split(",")[1].split(":")[1]
    );

    if (rolemake === "superadmin") {
      window.location.assign("/superadmindashboard");
    } else {
      window.location.assign("/admindashboard");
    }
  }

  async register() {
    if (!this.isValid) {
      this.allFields.forEach((field) => field.handleSubmit());
      return;
    }

    if (!this.confirmPasserror) {
      this.confimPass.setError("Password Mismatch");
      return;
    }

    if (!this.timezonevalid) {
      this.setError("Choose timezone");
      return;
    }

    if (!this.customerdatavalid) {
      this.setError("Choose Require Customer Data");
      return;
    }

    if (this.customerdata === "true") {
      this.customerdataboolean = true;
    } else {
      this.customerdataboolean = false;
    }

    const body = {
      user: {
        UserName: this.userName.value,
        EmailId: this.email.value,
        Password: this.password.value,
        PhoneNumber: this.phonenumber.value,
      },
      location: {
        LocationName: this.company.value,
        Address1: this.address1.value,
        Address2: this.address2.value,
        City: this.city.value,
        State: this.state.value,
        ZipCode: this.zipcode.value,
        T_and_C: this.termsandcondition(),
        // DefaultStayDuration: this.time.value,
        MainURL: this.locationurl.value,
        TimeZone: this.timezone,
        CustomerData: this.customerdataboolean,
      },
    };

    try {
      this.setWorking();

      const register: any = await ApiService.post(
        "User/createUserLocation",
        body
      );

      if (!register || !register.UserID) {
        this.setError(register);
      } else {
        window.location.assign("/usersuccesspage");
      }
      this.setReady();
    } catch (e) {
      this.setError(e);
    }
  }
}
