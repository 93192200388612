import { observer } from "mobx-react";
import * as React from "react";
import { FontAwesomeIcon } from "../../common/FontAwesomeIcon";
import { Button } from "react-bootstrap";

export interface IChangePswdSuccessPageComponentProps {}

export const ChangePswdSuccessPageComponent = observer(
  (props: IChangePswdSuccessPageComponentProps) => {
    return (
      <div className="jumbotron text-center text-lg-center shadow mb-5 bg-white rounded pt-4 mt-5 position-relative">
        <FontAwesomeIcon
          className="text-success check-icon "
          size="5x"
          icon="check-circle"
        />
        <div className="col-md-8 mx-auto col-sm-10 mt-3 text-md-center text-left">
          <h3 className="mb-4 pt-4 text-success">
            New Password Updated Successfully
          </h3>
          <Button
            onClick={() => {
              window.location.replace("/admindashboard");
            }}
            variant="success"
          >
            OK
          </Button>
        </div>
      </div>
    );
  }
);
