import { BaseForm } from "../forms/BaseForm";
import { observable, computed } from "mobx";
import { PhoneValidatedField } from "../forms/validated-fields/PhoneValidatedField";
import { ValidatedDateField } from "../forms/validated-fields/ValidatedDateField";
import { EmailValidatedField } from "../forms/validated-fields/EmailValidatedField";
import { ValidatedField } from "mobx-validated-field";
import ApiService from "../Auth/ApiService";

export class VisitorForm extends BaseForm {
  @observable phoneNo = new PhoneValidatedField({ required: true });
  @observable startDate = new ValidatedDateField({ required: true });
  @observable endDate = new ValidatedDateField({ required: true });
  @observable companyName: string;
  @observable email = new EmailValidatedField({ required: true });
  @observable maximumstayTime: number;
  @observable location: any;
  @observable termstext: string;
  @observable termsModal: boolean;
  @observable termsModal1: boolean;
  @observable termsModalError: boolean = false;
  @observable captcha: boolean;
  @observable captchaError: boolean = false;
  @observable shopId: string;
  @observable termsError: boolean = false;
  @observable terms: boolean;
  @observable MainURL: string;
  @observable skipsubmit: boolean;

  constructor() {
    super();
  }
  @computed
  get allFields(): ValidatedField[] {
    return [this.phoneNo, this.email];
  }

  @computed
  get valid(): boolean {
    return (
      this.allFields.every((field) => field.isValid) &&
      this.terms &&
      this.captcha
    );
  }

  async register() {
    if (!this.location || !this.location.LocationID) {
      return;
    }

    if (!this.valid) {
      if (!this.terms) this.termsError = true;
      if (!this.captcha) this.captchaError = true;
      this.email.handleSubmit();
      this.phoneNo.handleSubmit();
      return;
    }

    try {
      this.setWorking();
      const data = await ApiService.post("LocationVistLogs/create", {
        LocationID: this.location.LocationID,
        PhoneNumber: this.phoneNo.value,
        EmailId: this.email.value,
        VisitInTime: this.startDate.value,
        VisitOutTime: this.endDate.value,
      });
      return data;
    } catch (e) {
      this.setError(e);
    }
  }

  async load(id: string) {
    try {
      this.setWorking();
      const location = await ApiService.get(`Location/getLocation`, {
        params: {
          LocationID: id,
        },
      });

      if (!location) {
        this.setError("Location Not Found. Please Check the Url");
        return;
      }

      this.location = location;
      this.companyName = location.LocationName;
      this.maximumstayTime = location.DefaultStayDuration;
      let date = new Date();
      let dae = new Date();
      this.termstext = location.T_and_C;
      this.MainURL = location.MainURL;
      this.skipsubmit = location.CustomerData;
      this.shopId = id;
      dae.setMinutes(date.getMinutes() + this.maximumstayTime);
      this.phoneNo.handleChange("");
      this.startDate.handleChange(date);
      this.endDate.handleChange(dae);
      this.setReady();
    } catch (e) {
      this.setError(`${e} Please check the URL `);
    }
  }
}
