import React from "react";
import { Alert } from "react-bootstrap";
import { BaseForm } from "./BaseForm";
import { observer } from "mobx-react";
import { format } from "path";

export interface FormSuccessMessageProps {
  form: BaseForm;
}

export interface FormSuccessMessageState {
  show: boolean;
}
@observer
class FormSuccessMessage extends React.Component<
  FormSuccessMessageProps,
  FormSuccessMessageState
> {
  constructor(props: FormSuccessMessageProps) {
    super(props);
    this.state = {
      show: props.form.isSuccess,
    };
  }
  render() {
    if (this.props.form.isSuccess) {
      console.log(this.props.form.isSuccess);
      console.log(this.props.form.successMessage);
      return (
        <Alert variant="success">
          {/* <Alert.Heading>Success</Alert.Heading> */}
          <p>{this.props.form.successMessage}</p>
        </Alert>
      );
    }
    return <div></div>;
  }
}

export default FormSuccessMessage;
