import { IValidatedFieldConfig, ValidatedField, IValidatorConfig } from 'mobx-validated-field';

export class NameValidatedField extends ValidatedField {

    constructor(config?: IValidatedFieldConfig) {
        super(config);

        this.addValidators([
            NameValidator
        ]);
    }

    handleChange(val: string) {
        super.handleChange(val);
    }
}

export const NameValidator: IValidatorConfig = {
    id: 'bname',
    defaultMessage: "First letter shouldn't be empty space",

    validateOnSubmit: val => {
        if (val != null && val !== '' && !(/^[a-zA-Z0-9_'\-~?+!.,@%&]/.test(val))) {
            return false;
        }
    }
};
