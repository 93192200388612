import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FormGroup } from './FormGroup';

export interface IInputGroupProps {
    className?: string;
    controlId?: string;
    errorMessage?: string;
    helpText?: string;
    isInvalid?: boolean;
    isValid?: boolean;
    label?: string | React.ReactNode;
    prepend?: string | React.ReactNode;
    append?: string | React.ReactNode;
    as?: any;
    prependDownDown?: string | React.ReactNode;
    prependOnChange?: (val: string) => void;
    selectedPrePend?: string;
    maxLength?: number;
    minLength?: number;
    min?: number;
    max?: number;
    autoComplete?: string;
    onBlur?: (e: React.FocusEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onChange?: (e: React.ChangeEvent) => void;
    placeholder?: string;
    size?: 'sm' | 'lg';
    type?: string;
    value?: string;
    rows?: string;
    disabled?: boolean;
    autoFocus?: boolean;
}

export const InputGroup = observer(
    React.forwardRef((props: IInputGroupProps, ref: any) => {
        return (
            <FormGroup
                className={props.className}
                controlId={props.controlId}
                errorMessage={props.errorMessage}
                helpText={props.helpText}
                label={props.label}
                prependDownDown={props.prependDownDown}
                prependOnChange={props.prependOnChange}
                selectedPrePend={props.selectedPrePend}
                prepend={props.prepend}
                append={props.append}
            >
                <Form.Control
                    isInvalid={props.isInvalid}
                    isValid={props.isValid}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    min={props.min}
                    max={props.max}
                    autoComplete={props.autoComplete ? props.autoComplete : 'off'}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    size={props.size}
                    type={props.type || 'input'}
                    rows={props.rows}
                    value={props.value}
                    as={props.as}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    ref={ref}
                />
            </FormGroup>
        );
    })
);
