import { observer } from "mobx-react";
import * as React from "react";
import { FontAwesomeIcon } from "../common/FontAwesomeIcon";
// import { FontAwesomeIcon } from '../common/components/FontAwesomeIcon';

export interface ISuccessPageComponentProps {}

export const SuccessPageComponent = observer(
  (props: ISuccessPageComponentProps) => {
    return (
      <div className="jumbotron text-center text-lg-center shadow mb-5 bg-white rounded pt-4 mt-5 position-relative">
        <FontAwesomeIcon
          className="text-success check-icon "
          size="5x"
          icon="check-circle"
        />
        <div className="col-md-8 mx-auto col-sm-10 mt-3 text-md-center text-left">
          <h3 className="mb-4 pt-4 text-success">Registered successfully.</h3>
          <span>
            Thanks, successfully logged as an encrypted entry that gets auto
            deleted in the next 30 days
          </span>
        </div>
      </div>
    );
  }
);
