import * as React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { ValidatedInputGroup } from "../../forms/ValidatedInputGroup";
import { RegisterAdminForm } from "./registeradminform";
import FormErrorMessage from "../../forms/FormErrorMesssage";
import { MaskValidatedInputGroup } from "../../forms/MaskValidatedInputGroup";
import { observer } from "mobx-react";

export interface RegisterAdminComponentProps {
  form?: RegisterAdminForm;
}

export interface RegisterAdminComponentState {}

@observer
class RegisterAdminComponent extends React.Component<
  RegisterAdminComponentProps,
  RegisterAdminComponentState
> {
  form: RegisterAdminForm;
  constructor(props: RegisterAdminComponentProps) {
    super(props);
    this.form = new RegisterAdminForm();
  }

  // handleSubmit = async () => {
  //   const data: any = await this.props.form.register();

  //   if (data) {
  //     window.location.assign("/usersuccesspage");
  //   }
  // };
  handleadmin = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await this.form.register();
    }
  };

  handleChange = async () => {
    const emailvalid: any = await this.form.emailvalid(this.props.form.email);
  };

  handleChange1 = async () => {
    const pswdvalid: any = await this.form.pswdverify(
      this.props.form.password,
      this.props.form.confimPass
    );
  };

  render() {
    return (
      <Form onKeyDown={(e) => this.handleadmin(e)}>
        <FormErrorMessage form={this.form}></FormErrorMessage>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="UserName"
              label="Name"
              field={this.form.userName}
            />
          </Col>
          <Col>
            <ValidatedInputGroup
              fieldName="email"
              label="Email"
              field={this.form.email}
              onBlur={() => this.form.emailvalid(this.form.email.value)}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="password"
              label="Password"
              field={this.form.password}
            />
          </Col>
          <Col>
            <MaskValidatedInputGroup
              fieldName="c-password"
              label="Confirm Password"
              field={this.form.confimPass}
              onBlur={() =>
                this.form.pswdverify(
                  this.form.password.value,
                  this.form.confimPass.value
                )
              }
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <ValidatedInputGroup
              fieldName="phonenumber"
              label="Phone Number"
              field={this.form.phonenumber}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col className="text-center">
            <Button onClick={() => this.form.back()} variant="secondary">
              Back
            </Button>{" "}
            <Button
              onClick={() => this.form.register()}
              disabled={this.form.isWorking}
              variant="success"
            >
              {this.form.isWorking ? "Registering..." : "Make Super Admin"}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default RegisterAdminComponent;
