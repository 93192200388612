import { IValidatedFieldConfig, ValidatedField } from "mobx-validated-field";

export class PasswordValidatedField extends ValidatedField {
  constructor(config?: IValidatedFieldConfig) {
    super(config);

    this.addValidators([
      {
        id: "minLength",
        defaultMessage: "At least eight (8) characters long",
        validateOnChange: (val: string) => {
          if (val.length >= 4) {
            return true;
          }
        },
        validateOnSubmit: (val: string) => {
          if (val.length < 4) return false;
        },
      },
      {
        id: "uppercaseLetter",
        defaultMessage: "At least one (1) uppercase letter",
        validateOnChange: (val: string) => {
          const uppercase = /(?=.*[A-Z])/.test(val);
          if (uppercase) return true;
        },
        validateOnSubmit: (val: string) => {
          const uppercase = /(?=.*[A-Z])/.test(val);
          if (!uppercase) return false;
        },
      },
      {
        id: "lowercaseLetter",
        defaultMessage: "At least one (1) lower case letter",
        validateOnChange: (val: string) => {
          const lowercase = /(?=.*[a-z])/.test(val);
          if (lowercase) return true;
        },
        validateOnSubmit: (val: string) => {
          const lowercase = /(?=.*[a-z])/.test(val);
          if (!lowercase) return false;
        },
      },
    ]);
  }
  handleChange(val: string) {
    super.handleChange(val);
  }
}
