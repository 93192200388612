import React from "react";
import { Modal, Table } from "react-bootstrap";
import { observer } from "mobx-react";
import { BaseForm } from "../forms/BaseForm";

@observer
class Conditions extends React.Component {
  render() {
    return (
      <Modal.Body>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Thank you for choosing to be part of our community at Gravity
            Payments, Inc. (“Company”, “we”, “us”, or “our”). We are committed
            to protecting your personal information and your right to privacy.
            If you have any questions or concerns about our policy, or our
            practices with regards to your personal information, please contact
            us at info@gravitypayments.com. When you visit GravityCares, and use
            our services, you trust us with your personal information. We take
            your privacy very seriously. In this privacy policy, we seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy policy that you do
            not agree with, please discontinue use of our services. This privacy
            policy applies to all information collected through the GravityCares
            platform, and/or any related services, sales, marketing or events
            (we refer to them collectively in this privacy policy as the
            “Services”). Please read this privacy policy carefully as it will
            help you make informed decisions about sharing your personal
            information with us.
          </p>
          <p style={{ textAlign: "justify" }}>
            1. WHAT INFORMATION DO WE COLLECT?
          </p>
          <p>
            Personal information you disclose to us In Short: We collect
            personal information that you provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when expressing an interest in obtaining information about us or our
            products and services, when participating in activities on the
            Services or otherwise contacting us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make and
            the products and features you use. The personal information we
            collect can include the following:
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
          <p>Information automatically collected</p>
          <p>
            In Short: Some information — such as IP address and/or browser and
            device characteristics — is collected automatically when you visit
            our Services.
          </p>
          <p>
            We automatically collect certain information when you visit, use or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p style={{ textAlign: "justify" }}>
            2. HOW DO WE USE YOUR INFORMATION?
          </p>
          <p>
            In Short: We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </p>
          <p>
            We use personal information collected via our Services for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <p>
            To send you marketing and promotional communications. We and/or our
            third party marketing partners may use the personal information you
            send to us for our marketing purposes, if this is in accordance with
            your marketing preferences. You can opt-out of our marketing emails
            at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS” below).
          </p>
          <p>
            Fulfill and manage your orders. We may use your information to
            fulfill and manage your orders, payments, returns, and exchanges
            made through the Services.
          </p>
          <p>
            To post testimonials. We post testimonials on our Services that may
            contain personal information. Prior to posting a testimonial, we
            will obtain your consent to use your name and testimonial. If you
            wish to update, or delete your testimonial, please contact us at
            info@gravitypayments.com and be sure to include your name,
            testimonial location, and contact information.
          </p>
          <p>
            Deliver targeted advertising to you. We may use your information to
            develop and display content and advertising (and work with third
            parties who do so) tailored to your interests and/or location and to
            measure its effectiveness.
          </p>
          <p>
            Request Feedback. We may use your information to request feedback
            and to contact you about your use of our Services.
          </p>
          <p>
            To protect our Services. We may use your information as part of our
            efforts to keep our Services safe and secure (for example, for fraud
            monitoring and prevention).
          </p>
          <p>
            To enforce our terms, conditions and policies for Business Purposes,
            Legal Reasons and Contractual.
          </p>
          <p>
            To respond to legal requests and prevent harm. If we receive a
            subpoena or other legal request, we may need to inspect the data we
            hold to determine how to respond.
          </p>
          <p>
            For other Business Purposes. We may use your information for other
            Business Purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Services, products, marketing and your
            experience. We may use and store this information in aggregated and
            anonymized form so that it is not associated with individual end
            users and does not include personal information. We will not use
            identifiable personal information without your consent.
          </p>
          <p style={{ textAlign: "justify" }}>
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </p>
          <p>
            In Short: We only share information with your consent, to comply
            with laws, to provide you with services, to protect your rights, or
            to fulfill business obligations.
          </p>
          <p>
            We may process or share data based on the following legal basis:
          </p>
          <p>
            Consent: We may process your data if you have given us specific
            consent to use your personal information in a specific purpose.
          </p>
          <p>
            Legitimate Interests: We may process your data when it is reasonably
            necessary to achieve our legitimate business interests.
          </p>
          <p>
            Performance of a Contract: Where we have entered into a contract
            with you, we may process your personal information to fulfill the
            terms of our contract.
          </p>
          <p>
            Legal Obligations: We may disclose your information where we are
            legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
          </p>
          <p>
            Vital Interests: We may disclose your information where we believe
            it is necessary to investigate, prevent, or take action regarding
            potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal
            activities, or as evidence in litigation in which we are involved.
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <p>
            Vendors, Consultants and Other Third-Party Service Providers. We may
            share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Services,
            which will enable them to collect data about how you interact with
            the Services over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes.
          </p>
          <p>
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </p>
          <p style={{ textAlign: "justify" }}>
            4. WHO WILL YOUR INFORMATION BE SHARED WITH?
          </p>
          <p>
            In Short: We only share information with the following third
            parties.
          </p>
          <p>
            We only share and disclose your information with the following third
            parties. We have categorized each party so that you may be easily
            understand the purpose of our data collection and processing
            practices. If we have processed your data based on your consent and
            you wish to revoke your consent, please contact us.
            <br />
            Advertising,Direct Marketing, and Lead Generation
            <br />
            Google AdSense and Facebook Audience Network
            <br />
            Communicate and Chat with Users
            <br />
            Drift
            <br />
            Retargeting Platforms
            <br />
            Google Ads Remarketing , Google Analytics Remarketing, Facebook
            Custom Audience,
            <br />
            Facebook Remarketing and LinkedIn Website Retargeting
            <br />
            Social Media Sharing and Advertising
            <br />
            Facebook advertising, Facebook social plugins, Twitter advertising,
            Twitter social
            <br />
            plugins, Instagram advertising, LinkedIn advertising, LinkedIn
            social plugins and
            <br />
            YouTube social plugins
            <br />
            Web and Mobile Analytics
            <br />
            Google Analytics, Google Tag Manager, Facebook Ads conversion
            tracking and
            <br />
            Facebook Analytics
            <br />
            Website Hosting
            <br />
            Wordpress.org
          </p>
          <p style={{ textAlign: "justify" }}>
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </p>
          <p>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Policy.
          </p>
          <p style={{ textAlign: "justify" }}>
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </p>
          <p>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy policy unless
            otherwise required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy policy, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            policy will require us keeping your personal information for longer
            than 2 years.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize it, or, if
            this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing
            until deletion is possible.
          </p>
          <p style={{ textAlign: "justify" }}>
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </p>
          <p>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we have collected from children under age
            18, please contact us at info@gravitypayments.com.
          </p>
          <p style={{ textAlign: "justify" }}>
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </p>
          <p>
            In Short: You may review, change, or terminate your account at any
            time.
          </p>
          <p>
            If you are resident in the European Economic Area and you believe we
            are unlawfully processing your personal information, you also have
            the right to complain to your local data protection supervisory
            authority. You can find their contact details here: http://
            ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          </p>
          <p>
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. To opt-out of
            interest-based advertising by advertisers on our Services visit
            http://www.aboutads.info/choices/.
          </p>
          <p style={{ textAlign: "justify" }}>
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </p>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. No
            uniform technology standard for recognizing and implementing DNT
            signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy policy.
          </p>
          <p style={{ textAlign: "justify" }}>
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </p>
          <p>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </p>
          <p>
            This section applies solely to visitors and users of our site who
            reside in the State of California. We have adopted this notice to
            comply with the California Consumer Privacy Act of 2018 (the “CCPA”)
            and the California Online Privacy Protection Act (“CalOPPA”), and
            any terms defined in the CCPA or CalOPPA have the same meaning when
            used in this notice.
          </p>
          <p>
            For the purposes of this section “California Data Subject” shall
            mean: (1) an individual who is in the State of California for other
            than a temporary or transitory purpose, and (2) an individual who is
            domiciled in the State of California who is outside the State of
            California for a temporary or transitory purpose.
          </p>
          <p style={{ textAlign: "justify" }}>
            <u>Information We Collect</u>
          </p>
          <p>
            Gravity collects information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular California Data
            Subject or device ("personal information").
          </p>
          <p>
            In particular, Gravity has collected the following categories of
            personal information from California Data Subjects within the last
            twelve (12) months:
          </p>

          <Table bordered size="sm">
            <thead>
              <tr>
                <th>
                  <u>Category</u>
                </th>
                <th>
                  <u>Examples</u>
                </th>
                <th>
                  <u>Do we collect this data?</u>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Identifiers</td>
                <td>
                  Real name, alias, postal address, unique personal identifier,
                  online identifier, internet protocol (IP) address, email
                  address, account name, or other similar identifiers
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  Characteristics of protected classifications under California
                  or federal law
                </td>
                <td>Race, gender, ethnicity, disability status</td>
                <td>
                  <b>
                    <u>Yes</u>
                  </b>
                </td>
              </tr>
              <tr>
                <td>Commercial information</td>
                <td>
                  Records of personal property, products, or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies
                </td>
                <td>No</td>
              </tr>
              <tr>
                <td>Biometric information</td>
                <td>Fingerprint, facial pattern, voice, typing cadence</td>
                <td>
                  <b>
                    <u>No</u>
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Internet or other electronic network a c t i v i t y
                  information
                </td>
                <td>Information regarding usage of a site, software, or app</td>
                <td>
                  <u>Yes</u>
                </td>
              </tr>
              <tr>
                <td>Geolocation data</td>
                <td>Physical location</td>
                <td>
                  <b>
                    <u>Yes</u>
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Audio, electronic, visual, thermal, olfactory , o r similar
                  information
                </td>
                <td>Recordings of a California Data Subject</td>
                <td>No</td>
              </tr>
              <tr>
                <td>Professional or employment- r e l a t e d information</td>
                <td>
                  Place of work, current occupation, duration of occupation,
                  position/title
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Education Information</td>
                <td>
                  Information that is not publicly available personally
                  identifiable information as defined in the Family Educational
                  Rights and Privacy Act (FERPA)
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  Inferences drawn from any of the information identified above
                </td>
                <td>
                  Information used to create a profile about the California Data
                  Subject reflecting their preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes
                </td>
                <td>
                  <b>
                    <u>No</u>
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>

          <p>
            <u>Sources of Personal Information</u>. Gravity obtains the personal
            information listed above from the following sources:
          </p>

          <Table bordered>
            <thead>
              <tr>
                <th>
                  <u>Source</u>
                </th>
                <th>
                  <u>Examples</u>
                </th>
                <th>
                  <u>Do we collect this data?</u>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Directly from you</td>
                <td>
                  From forms you complete or orders for products and services
                  you purchase.
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Indirectly from you</td>
                <td>From observing your actions on our services.</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Third Parties</td>
                <td>
                  We are provided information by our third party vendors such
                  as: [our Merchant Partners, our Technology Hosting Partners,
                  and our Marketing Partners]
                </td>
                <td>Yes</td>
              </tr>
            </tbody>
          </Table>

          <p>
            <u>Use of Personal Information</u>. We may use or disclose the
            personal information we collect for one or more of the following
            business purposes:
          </p>

          <ol type="A">
            <li>
              To fulfill the purpose for which you provided the information. For
              example, if you share your name and contact information to request
              a price quote or ask a question about our products or services, we
              will use that personal information to respond to your inquiry. If
              you provide your personal information to purchase a product or
              service, we will use that information to process your payment and
              facilitate delivery. We may use personal information you provide
              us to provide technical support. In addition, we may use the above
              information:
              <ol type="a">
                <li>
                  To provide, support, personalize, and develop our websites,
                  products, and/or services;
                </li>
                <li>
                  To create, maintain, customize, and secure your account with
                  us;
                </li>
                <li>
                  To process your requests, purchases, transactions, and
                  payments and prevent transactional fraud;
                </li>
                <li>
                  To provide you with support and to respond to your inquiries,
                  including to investigate and address your concerns and monitor
                  and improve our responses;
                </li>
                <li>
                  To help maintain the safety, security, and integrity of our
                  Website, products and services, databases and other technology
                  assets, and business;
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations; and
                </li>
                <li>
                  As described to you when collecting your personal information
                  or as otherwise set forth in the CCPA.
                </li>
              </ol>
            </li>
          </ol>

          <p>
            Gravity will not collect additional categories of personal
            information or use the personal information we collected for
            materially different, unrelated, or incompatible purposes without
            providing you notice.
          </p>
          <p>
            Gravity does not sell your personal information to third parties.
            Gravity does share personal information with our third party service
            providers and vendors in order to provide you the Service.
          </p>
          <p>
            <u>Your Rights and Choices</u>
          </p>
          <p>
            This section describes your CCPA rights and explains how to exercise
            those rights.
          </p>
          <p>
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past twelve (12) months. Once we receive and verify your
            request, we will disclose to you:
          </p>

          <ol type="A">
            <li>
              The categories of personal information we collected about you;
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you;
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information;
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information;
            </li>
            <li>
              The specific pieces of personal information we collected about you
              (also called a data portability request);
            </li>
            <li>
              If we sold or disclosed your personal information for a business
              purpose, two separate lists disclosing:
              <ol type="a">
                <li>
                  sales, identifying the personal information categories that
                  each category of recipient purchased; and
                </li>
                <li>
                  disclosures for a business purpose, identifying the personal
                  information categories that each category of recipient
                  obtained.
                </li>
              </ol>
            </li>
          </ol>

          <p style={{ textAlign: "justify" }}>
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and verify your request, we will
            delete (and direct our service providers to delete) your personal
            information from our records, unless an exception applies.
          </p>
          <p style={{ textAlign: "justify" }}>
            We may deny your disclosure and/or deletion request if retaining the
            information is necessary for us or our service providers to:
          </p>

          <ol type="A">
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform our contract
              with you;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities;
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality;
            </li>
            <li>
              Exercise free speech, ensure the right of another California Data
              Subject to exercise their free speech rights, or exercise another
              right provided for by law;
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 seq.);
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if you previously provided informed
              consent;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              California Data Subject expectations based on your relationship
              with us;
            </li>
            <li>
              Comply with a legal obligation, such as other privacy or data
              protection statutes and regulations that place restrictions on our
              ability to disclose information including, but not limited to, the
              Gramm–Leach–Bliley Act, PCI Data Security Standards, and state
              privacy acts; and
            </li>
            <li>
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ol>

          <p style={{ textAlign: "justify" }}>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable California Data Subject
            request to us by sending us an email at support@Gravity.com or
            calling us at 206-802-1363.
          </p>
          <p style={{ textAlign: "justify" }}>
            Only you or a person registered with the California Secretary of
            State, that you authorize to act on your behalf, may make a
            verifiable California Data Subject request related to your personal
            information. You may also make a verifiable California Data Subject
            request on behalf of your minor child.
          </p>
          <p style={{ textAlign: "justify" }}>
            You may only make a verifiable California Data Subject request for
            access or data portability twice within a twelve (12) month period.
            The verifiable California Data Subject request must:
          </p>

          <ol type="A">
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative; and
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ol>

          <p style={{ textAlign: "justify" }}>
            We cannot respond to your request or provide you with personal
            information if we cannot verify your identity or authority to make
            the request and confirm the personal information relates to you.
            Making a verifiable California Data Subject request does not require
            you to create an account with us. We will only use personal
            information provided in a verifiable California Data Subject request
            to verify the requestor's identity or authority to make the request.
          </p>
          <p style={{ textAlign: "justify" }}>
            We aspire to respond to a verifiable California Data Subject request
            within forty five (45) days of receipt of the request. If we require
            more time (up to ninety (90) days) we will inform you of the
            reason(s) why an extension is needed and how long we anticipate the
            period to be. Any disclosure we provide will only cover the twelve
            (12) month period preceding the receipt of your request. If
            applicable, the response may provide the reasons why we cannot
            comply with your request. For data portability requests, we will
            select a format to provide your personal information that is readily
            useable and should allow you to transmit the information from one
            entity to another entity without hindrance.
          </p>
          <p style={{ textAlign: "justify" }}>
            We do not charge a fee to process or respond to your verifiable
            California Data Subject request unless it is excessive, repetitive,
            or manifestly unfounded. If we determine that the request warrants a
            fee, we will tell you why we made that decision and provide you with
            a cost estimate before completing your request. We reserve the right
            to refuse to respond to verifiable California Data Subject requests
            that are excessive, repetitive, or manifestly unfounded.
          </p>
          <p>
            <u>Right of Non-Discrimination.</u>
          </p>
          <p style={{ textAlign: "justify" }}>
            We will not discriminate against you for exercising any of your CCPA
            rights. We will not take any of the following actions against you in
            response to an exercise of your rights:
          </p>

          <ol type="A">
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
          </ol>

          <p style={{ textAlign: "justify" }}>
            Suggest that you may receive a different price or rate for goods or
            services or a different level or quality of goods or services.
          </p>
          <p style={{ textAlign: "justify" }}>
            11. DO WE MAKE UPDATES TO THIS POLICY?
          </p>
          <p>
            In Short: Yes, we will update this policy as necessary to stay
            compliant with relevant laws.
          </p>
          <p>
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy policy, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </p>
          <p style={{ textAlign: "justify" }}>
            12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
          </p>
          <p>
            <br />
            If you have questions or comments about this policy, you may email
            us
            <br />
            at info@gravitypayments.com or by post to:
            <br />
            Gravity Payments, Inc.
            <br />
            5601 22ND STREET NW
            <br />
            STE 200
            <br />
            Seattle, WA 98107
            <br />
            United States
          </p>
          <p style={{ textAlign: "justify" }}>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </p>
          <p style={{ textAlign: "justify" }}>
            Based on the laws of some countries, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request form by clicking here. We will respond to
            your request within 30 days.
          </p>
        </Modal.Body>
      </Modal.Body>
    );
  }
}

export default Conditions;
