import { observable, computed, action } from "mobx";
import ApiService from "../../Auth/ApiService";
import { EditLocationForm } from "./EditLocationForm";

export class EditLocationStore {
  @observable isLoading: boolean = false;

  @observable form: EditLocationForm;

  constructor() {
    this.form = new EditLocationForm();
  }

  @action
  async load() {
    try {
      this.isLoading = !this.isLoading;
      const locationid = JSON.parse(localStorage.getItem("user")).LocationID;

      const data = await ApiService.get(`Location/superadmingetLocation`, {
        params: {
          LocationID: locationid,
        },
      });

      if (data) {
        this.form.initializeFields(data);

        this.isLoading = !this.isLoading;
      }
    } catch (e) {
      this.isLoading = !this.isLoading;
    }
  }
}
