import { IValidatedFieldConfig, ValidatedField } from 'mobx-validated-field';

const POSTAL_ZIP_RE = /^\d{5}-\d{4}|\d{5}|[a-zA-Z]\d[a-zA-Z][ -]?\d[a-zA-Z]\d$/;

export class PostalCodeValidatedField extends ValidatedField {
    constructor(opts?: IValidatedFieldConfig) {
        super(opts);

        this.addValidators([
            {
                id: 'postal_code',
                defaultMessage: 'Invalid zip/postal code',
                validateOnSubmit: (val) => {
                    if (val) {
                        return POSTAL_ZIP_RE.test(val);
                    }
                },
            },
        ]);
    }

    handleChange(val: string) {
        super.handleChange((val));
    }
}

export function maskZip(zip: string): string {
    if (zip == null || zip === '') {
        return zip;
    }

    // allow to input hypen
    if (zip.length === 6 && zip[5] === '-') {
        return zip;
    }

    var x = zip.replace(/\D/g, '').match(/(\d{0,5})(\d{0,4})/);

    return !x[2] ? x[1] : x[1] + '-' + x[2];
}
