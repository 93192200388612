import { observer } from "mobx-react";
import { ValidatedField } from "mobx-validated-field";
import * as React from "react";
import { IInputGroupProps, InputGroup } from "./InputGroup";

export interface IValidatedInputGroupProps {
  field: ValidatedField;
  fieldName: string;
  className?: string;
  helpText?: string;
  label?: string | React.ReactNode;
  prepend?: string | React.ReactNode;
  append?: string | React.ReactNode;
  as?: any;
  autoComplete?: string;
  maxLength?: number;
  min?: number;
  max?: number;
  minLength?: number;
  type?: string;
  rows?: string;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  autoFocus?: boolean;
}

export const ValidatedInputGroup = observer(
  React.forwardRef((props: IValidatedInputGroupProps, ref) => {
    const { field, fieldName } = props;

    const handleChange = (e: React.ChangeEvent<any>) => {
      if (props.disabled) {
        e.preventDefault();
        return;
      }
      const value = e.target.value;
      field.handleChange(value);
    };

    const handleBlur = (e: React.FocusEvent) => {
      field.handleSubmit();
      props.onBlur && props.onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent) => {
      props.onFocus && props.onFocus(e);
    };

    const inputProps: IInputGroupProps = {
      controlId: `form:${fieldName}`,
      errorMessage: field.firstErrorMessage,
      helpText: props.helpText,
      className: props.className,
      isInvalid: field.hasError,
      isValid: field.value && field.isValid,
      label: props.label,
      prepend: props.prepend,
      append: props.append,
      maxLength: props.maxLength,
      minLength: props.minLength,
      autoComplete: props.autoComplete,
      min: props.min,
      max: props.max,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onChange: handleChange,
      placeholder: field.placeholder,
      type: props.type,
      value: field.value,
      rows: props.rows,
      as: props.as,
      disabled: props.disabled,
      autoFocus: props.autoFocus,
    };
    return <InputGroup ref={ref} {...inputProps} />;
  })
);
