import * as React from "react";
import { Form, Alert, Col, Button, Navbar, Nav } from "react-bootstrap";
import { ValidatedInputGroup } from "../forms/ValidatedInputGroup";
import { LoginForm } from "./LoginPageForm";
import { MaskValidatedInputGroup } from "../forms/MaskValidatedInputGroup";
import { observer } from "mobx-react";
import FormErrorMessage from "../forms/FormErrorMesssage";
import logo from "../Gravitycareslogo.jpg";
import logo1 from "../Corezilogo_Small.png";

export interface LoginPageProps {}

export interface LoginPageState {}

@observer
class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  form: LoginForm;
  constructor(props: LoginPageProps) {
    super(props);
    this.state = {};
    this.form = new LoginForm();
  }

  handleLogin = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const res = await this.form.login();

      if (!res) {
        return;
      } else {
        //OTP Module stopped by gravity cares request::
        //window.location.replace("/OtpVerification");
        const rolemake: any = JSON.parse(
          localStorage.getItem("user").split(",")[1].split(":")[1]
        );

        if (rolemake !== "superadmin") {
          window.location.replace("/admindashboard");
        } else {
          window.location.replace("/superadmindashboard");
        }
      }
    }
  };

  handleLogin1 = async () => {
    const res = await this.form.login();

    if (!res) {
      return;
    } else {
      //OTP Module stopped by gravity cares request::
      //window.location.replace("/OtpVerification");
      const rolemake: any = JSON.parse(
        localStorage.getItem("user").split(",")[1].split(":")[1]
      );

      if (rolemake !== "superadmin") {
        window.location.replace("/admindashboard");
      } else {
        window.location.replace("/superadmindashboard");
      }
    }
  };

  render() {
    const { form } = this;

    return (
      <div>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
            <img
              alt=""
              src={logo}
              width="120"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <img alt="" src={logo1} width="120" height="30" />
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
        <div className="container card bg-white p-5 mt-5">
          <Form onKeyDown={(e) => this.handleLogin(e)}>
            <FormErrorMessage form={form}></FormErrorMessage>
            <Form.Label>Login</Form.Label>
            <Form.Row className="mb-2">
              <Col>
                <ValidatedInputGroup
                  fieldName="username"
                  label="User Name"
                  field={form.userName}
                  disabled={this.form.isWorking}
                ></ValidatedInputGroup>
              </Col>
            </Form.Row>
            <Form.Row className="mb-2">
              <Col>
                <MaskValidatedInputGroup
                  fieldName="password"
                  label="Password"
                  field={form.password}
                  disabled={this.form.isWorking}
                ></MaskValidatedInputGroup>
                <Form.Text className="text-right">
                  <a target="_self" href="/forgetpassword/otp">
                    <u>Forget Password !</u>
                  </a>
                </Form.Text>
              </Col>
            </Form.Row>

            <Button
              variant="success"
              onClick={() => this.handleLogin1()}
              disabled={this.form.isWorking}
            >
              {this.form.isWorking ? "Loading" : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default LoginPage;
