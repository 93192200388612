import {
  IValidatedFieldConfig,
  IValidatorConfig,
  ValidatedField,
} from "mobx-validated-field";

export class OtpDigitValidatedField extends ValidatedField {
  constructor(config?: IValidatedFieldConfig) {
    super(config);

    this.addValidators([
      {
        id: "mismatchLength",
        defaultMessage: "OTP must be of (6) Digits Only",
        validateOnChange: (val: string) => {
          if (val.length > 6 || val.length < 6) {
            console.log("Entry 03");
            return true;
          }
        },
        validateOnSubmit: (val: string) => {
          console.log("Entry 04");
          if (val.length > 6 || val.length < 6) return false;
        },
      },
      {
        id: "numOnly",
        defaultMessage: "Invalid Input, OTP Must be of Numbers",

        validateOnChange: (val) => {
          if (val != null && val !== "" && !/^[0-9]*$/.test(val)) {
            return false;
          }
        },
        validateOnSubmit: (val) => {
          if (val != null && val !== "" && !/^[0-9]*$/.test(val)) {
            return false;
          }
        },
      },
    ]);
  }
  handleChange(val: string) {
    super.handleChange(val);
  }
}
