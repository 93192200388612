import React from "react";
// import LocationList from "../locationAdmin/location-list/LocationListPage";
import SuperAdminLocationList from "../superAdmin/superadmin-location-list/SuperAdminLocationListPage";
import { SuperAdminLocationListStroe } from "../superAdmin/superadmin-location-list/SuperAdminLocationListStore";
import { Navbar, Button, Nav } from "react-bootstrap";
import AuthService, { QR } from "../Auth/AuthService";
import logo from "../Gravitycareslogo.jpg";
import { observer, inject } from "mobx-react";

export interface SuperAdminPageProps {
  user?: any;
}

export interface SuperAdminPageState {}

@inject("user")
@observer
class SuperAdminPage extends React.Component<
  SuperAdminPageProps,
  SuperAdminPageState
> {
  store: SuperAdminLocationListStroe;
  constructor(props: SuperAdminPageProps) {
    super(props);
    this.state = {};
    this.store = new SuperAdminLocationListStroe();
  }

  componentDidMount() {
    this.store.load();
  }

  render() {
    return (
      <div className="hieght width-fit">
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/superadmindashboard">
            <img
              alt=""
              src={logo}
              width="150"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <Button
                onClick={() => {
                  window.location.replace("/register");
                }}
              >
                Register Location
              </Button>{" "}
              {/* </Nav.Item>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item> */}
              <Button
                onClick={() => {
                  window.location.replace("/registeradmin");
                }}
              >
                Make Super Admin
              </Button>{" "}
              <Button
                onClick={() => {
                  AuthService.logout();
                  window.location.replace("/login");
                }}
              >
                Logout
              </Button>
            </Nav.Item>
          </Navbar.Collapse>
        </Navbar>
        <div className=" p-5">
          <SuperAdminLocationList store={this.store}></SuperAdminLocationList>
        </div>

        <Navbar className="justify-content-center" fixed="bottom">
          <span>Powered by Gravity Payments and Corezi.com</span>
        </Navbar>
      </div>
    );
  }
}

export default SuperAdminPage;
